import styled from 'styled-components';
import { lighten } from 'polished';
import { Card, CardActions, CardContent } from '@material-ui/core';

import Modal from 'react-bootstrap/Modal';

export const Container = styled.div`
  display: flex;
  min-height: 100vh;
  height: 100%;
  background-color: #f5f5f5;
`;

export const Group = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100vw;
`;

export const ContentPDCA = styled.div`
  background: #fff;
  height: 100%;
  margin: 5%;
  padding: 18px 18px;
  border-radius: 6px;
  box-shadow: 4px 4px 2px rgba(0, 0, 0, 0.1);
  justify-content: center;
  flex-direction: column;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 2px #9c9c9c solid;

  h1 {
    color: #753f6e;
    letter-spacing: 2px;
    font-weight: bold;
    margin-bottom: 0.625rem;
  }
`;

export const ButtonPlanAction = styled.button`
  background: #ffb13d;
  margin: auto 0.3rem auto 0.75rem;
  border-radius: 0.3rem;
  color: #fff;
  padding: 0.3rem;
  cursor: pointer;
  border: 2px #ffb13d solid;
  font-size: 0.9rem;
  font-weight: bold;

  &:hover {
    background: #fff;
    color: #ffb13d;
  }
`;

export const DivConfig = styled.div`
  font-family: 'Acme', sans-serif !important;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 0.625rem;
`;

export const CardCustom = styled(Card)`
  border: 1px solid #ddd;
  border-radius: 10px;
  height: 100%;
  align-items: flex-end;
  margin: 5px;
`;

export const CardActionsCustom = styled(CardActions)`
  margin-bottom: 0;
  display: flex;
  align-items: flex-end;
  flex-direction: row;

  svg {
    margin-left: 2%;
    cursor: pointer;
    color: #363636;
  }
`;
export const ButtonAcessar = styled.button`
  border: 0;
  padding: 8px 10px;
  background: #ff9000;
  font-size: 0.875rem;
  color: #ffffff;
  border-radius: 5px;
  transition: 0.2s;
  margin-top: 10px;
  margin-bottom: 5px;
  font-weight: bold;

  &:hover {
    background: ${lighten(0.2, '#ff9000')};
  }
`;

export const CardContentCustom = styled(CardContent)`
  flex-direction: column;
  p {
    margin: auto;
    color: #6e6e6e;
  }
  span {
    color: #000;
  }
`;

export const TitleCard = styled.h5`
  font-size: 1.6rem !important;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: auto;
  color: #957290 !important;
  cursor: pointer;
  border-bottom: 1px #957290 solid;
  font-family: 'Acme', sans-serif !important;
`;

export const ModalStyled = styled(Modal)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 98vw;

  div {
    background: #f3f3f3;
    justify-content: center;
    align-items: center;
    border-radius: 10px;

    span.label {
      padding: 0.5rem;
      color: #5b437a;
    }
  }

  h1 {
    color: #957290;
    border: 0;
    margin-top: 0.5rem;

    svg {
      position: absolute;
      right: 0.75rem;
      top: 0.75rem;
      color: #ff9000;
      cursor: pointer;
    }
  }

  Form {
    max-width: 300px;
    width: 98%;
    display: flex;
    flex-direction: column;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
`;
