import styled, { css } from 'styled-components';
import { lighten } from 'polished';
import {
  TableFooter,
  TableSortLabel,
  TableCell,
  Table,
  Typography,
} from '@material-ui/core';
import Modal from 'react-bootstrap/Modal';

export const TableSortLabelCustom = styled(TableSortLabel)`
  background: #6fa7c7;
  color: #ffffff !important;
  border-radius: 1rem;
  justify-content: center;
  align-items: center;
  font-size: 1rem !important;

  svg {
    margin: auto;
    width: 26px;
    height: 26px;
    color: #6e005e !important;
  }
`;

interface DivProps {
  type?:
    | 'Pendente'
    | 'Andamento'
    | 'Concluído'
    | 'Cancelado'
    | 'Feedback'
    | undefined;
}

const DivTypeVariations = {
  Pendente: css`
    background: #fff;
    color: #ff5370;
    border: 2px solid #ff5370;
    padding: 5px;
    border-radius: 0.5rem;
  `,
  Andamento: css`
    background: #fff;
    color: #456eff;
    border: 2px solid #456eff;
    padding: 5px;
    border-radius: 0.5rem;
  `,
  Concluído: css`
    background: #fff;
    color: #0ec716;
    border: 2px solid #0ec716;
    padding: 5px;
    border-radius: 0.5rem;
  `,
  Cancelado: css`
    background: #fff;
    color: #000;
    border: 2px solid #000;
    padding: 5px;
    border-radius: 0.5rem;
  `,
  Feedback: css`
    background: #fff;
    color: #692d60;
    border: 2px solid #692d60;
    padding: 5px;
    border-radius: 0.5rem;
  `,
};

export const Tdstatus = styled.div<DivProps>`
  display: flex;
  justify-content: center;
  ${(props) => DivTypeVariations[props.type || 'Pendente']}
`;

export const TableCustom = styled(Table)`
  min-width: 750px;
  width: 90% !important;
  margin: auto;
  background-color: #fff;
`;

export const TableCellCustom = styled(TableCell)`
  font-weight: bold !important;
  font-size: 1rem !important;
  @keyframes light {
    from {
      background: #ffc657;
      color: #000;
      border-radius: 0.5rem;
    }
    to {
      background: white;
      border-radius: 0.5rem;
    }
  }

  .alertDate {
    background: #ffc657 !important;
    border-radius: 10px;
    padding: 0.5rem;
  }

  button {
    border: 0;
    padding: 3px 8px;
    background: transparent;
    border-radius: 4px;
    margin-right: 2px;

    svg {
      transition: 0.2s;
      color: #555;
      &:hover {
        background: ${lighten(0.2, '#757575')};
      }
    }
  }

  button.notification {
    padding: 0.5rem;
    svg {
      animation: light 1s alternate infinite ease-in-out;
      margin: auto;
      width: 26px;
      height: 26px;
      color: #6e005e !important;
    }
  }

  button.notNotification {
    svg {
      transition: 0.2s;
      margin: auto;
      width: 26px !important;
      height: 26px !important;
      color: #ff9800 !important;
    }
  }
`;

export const TableCellHeaderCustom = styled(TableCell)`
  background: #878787;
  color: #ffffff !important;
  border-radius: 1rem;
  justify-content: center !important;
  align-items: center;
  padding: 0.4rem !important;
`;

export const TableFooterCustom = styled(TableFooter)`
  background: #fff;
  width: 90%;
  margin: auto;
`;

export const DivText = styled.div`
  p {
    font-size: 0.95rem !important;
    text-align: justify !important;
    word-break: break-word !important;
  }
`;

export const DivDetails = styled.div`
  margin: auto;
  flex-direction: column;

  strong {
    font-size: 1rem !important;
    color: #636363 !important;
    margin: 0.75rem !important;
  }

  span {
    font-size: 1rem !important;
    text-align: justify !important;
    svg {
      color: #6e005e !important;
      width: 22px !important;
      height: 22px !important;
    }
  }
`;

export const TypographyCustom = styled(Typography)`
  color: #957290;
  font-size: 2rem !important;
  margin-top: 0.5rem !important;
  margin-bottom: 1rem !important;
  font-weight: bold !important;

  hr {
    border: 1px solid #878787 !important;
    background-color: #878787 !important;
    width: 100% !important;
    margin: auto;
  }

  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
  }
`;

export const TitleDetails = styled.h3`
  color: #878787;
  font-size: 1.5rem !important;
  margin: auto !important;
  width: 100% !important;
`;

export const ModalStyled = styled(Modal)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 98vw;

  p {
    font-size: 1.2rem;
    color: #363636;
  }

  div {
    background: #f3f3f3;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.2rem;
    border-radius: 10px;

    h1 {
      color: #957290;
      border: 0;
      margin-top: 0.5rem;

      svg {
        position: absolute;
        right: 0.75rem;
        top: 0.75rem;
        color: #ff9000;
        cursor: pointer;
      }
    }
  }
`;

export const Group = styled.div`
  padding: 1.25rem;
  display: flex;
  flex-direction: row;
  margin-top: 1.25rem;

  h3 {
    color: #39215b;
    font-size: 1.125rem;
    margin-bottom: 8px;
  }

  strong {
    font-weight: bold;
    color: #404040;
    padding-bottom: 4px;
  }

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export const Col2 = styled.div`
  padding: 0.625rem;
  width: 410px;
  height: 360px;
  margin-left: 0.75rem;
  overflow-y: auto;
  overflow-x: hidden;
  display: block;

  div {
    margin-top: 0.625rem;

    hr {
      margin-top: 0.5rem;
      border-color: ${lighten(0.1, '#a020f0')};
    }

    strong {
      font-weight: bold;
      color: #404040;
      font-size: 1rem !important;
      padding-bottom: 1rem;

      span {
        color: #a1a1a1;
        font-size: 0.75rem;
      }
    }
    p {
      color: #555555;
      margin: 5px 0;
      word-break: break-all;
    }
  }

  @media (max-width: 702px) {
    width: 280px;
    min-height: 0;
    margin: 0;
    overflow: auto;
    padding: 15px;
  }
`;

export const ButtonDelete = styled.button`
  width: 1.75rem;
  height: 1.75rem;
  border: 0;
  padding: 1px 4px;
  background: #b50012;
  color: #fff;
  border-radius: 4px;
  margin-left: 4%;
`;

export const Col1 = styled.div`
  display: block !important;
  align-items: center;
  justify-content: center;

  textarea {
    display: block;
    color: #555;
    width: 100%;

    &::placeholder {
      color: #999999;
      font-size: 0.875rem;
    }
  }
`;
