import React, { useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { FiMail } from 'react-icons/fi';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { toast } from 'react-toastify';
import Lottie from 'react-lottie';

import { useAuth } from '../../hooks/auth';
import getValidationErrors from '../../utils/getValidationErrors';

import {
  Container,
  Content,
  AnimationContainer,
  Background,
  Image,
  Title,
} from './styles';

import logoImg from '../../assets/logo.svg';
import emailLottie from '../../assets/email.json';

import Input from '../../components/Input';
import Button from '../../components/Button';

interface SignInFormData {
  email: string;
}

const SignInEmail: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { emailExist, info } = useAuth();
  const history = useHistory();

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um e-mail válido'),
        });

        await schema.validate(data, { abortEarly: false });
        await emailExist({
          email: data.email.toLocaleLowerCase(),
        });

        history.push('/signinpassword');
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          formRef.current?.setErrors(errors);

          return;
        }

        if (!info?.email) {
          toast.error('Verifique o e-mail digitado.');
          return;
        }

        toast.error('Ocorreu um erro ao fazer logon, cheque as crendeciais');
      }
    },
    [emailExist, history, info],
  );

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <Image src={logoImg} alt="SimplesPlan" />
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Title>Insira seu e-mail</Title>

            <Input
              name="email"
              icon={FiMail}
              placeholder="E-mail"
              type="email"
              required
            />

            <Button type="submit">Avançar</Button>
          </Form>
        </AnimationContainer>
      </Content>
      <Background>
        <Lottie
          options={{
            animationData: emailLottie,
            autoplay: true,
            loop: true,
          }}
          width="100%"
        />
      </Background>
    </Container>
  );
};

export default SignInEmail;
