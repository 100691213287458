import React, { useRef, useEffect, SelectHTMLAttributes } from 'react';

import { useField } from '@unform/core';

import { Content } from './styles';

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  name: string;
  data?: SelectRequest[];
}

interface SelectRequest {
  id: string;
  value: string;
  selectedOption?: boolean;
}

const Select: React.FC<SelectProps> = ({ name, data, ...rest }) => {
  const selectRef = useRef(null);
  const { fieldName, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Content>
      <select ref={selectRef} placeholder="Selecionar" {...rest}>
        {data &&
          data.map((status) => (
            <option
              selected={status.selectedOption}
              key={status.id}
              value={status.id}
            >
              {status.value}
            </option>
          ))}
      </select>
    </Content>
  );
};

export default Select;
