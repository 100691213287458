import React, { useState, useEffect, useCallback, useRef } from 'react';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import Modal from 'react-bootstrap/Modal';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import Avatar from '@material-ui/core/Avatar';

import {
  FiUser,
  FiMail,
  FiArrowRightCircle,
  FiSave,
  FaWhatsapp,
  FiXCircle,
  FiLogOut,
  TiArrowForwardOutline,
  RiShieldUserFill,
} from 'react-icons/all';

import { toast } from 'react-toastify';

import {
  MenuCustom,
  Image,
  NavLeft,
  NavCenter,
  NavRight,
  NavBarCustom,
  NavDropdownCustom,
  MenuLink,
  MenuConfig,
  MenuItemConfig,
  InfoAvatar,
  TextUser,
  Row,
  MenuLink2,
  ModalStyled,
  Form,
} from './styles';

import { useAuth } from '../../hooks/auth';
import { useTask } from '../../hooks/task';
import { useUser } from '../../hooks/user';
import { usePDCA } from '../../hooks/pdca';

import TextArea from '../../components/TextArea';
import Button from '../../components/Button';
import SelectCreateUser from '../../components/SelectCreateUser';
import Input from '../../components/Input';
import CreateEditUser from '../../components/CreateEditUser';
import getValidationErrors from '../../utils/getValidationErrors';

import logoImg from '../../assets/logo.svg';

interface PDCAFormData {
  name: string;
  description: string;
}

const Header: React.FC = () => {
  const [modalCreateUser, setModalCreateUser] = useState(false);
  const [modalClosePdca, setModalClosePdca] = useState(false);
  const [modalCloseUser, setModalCloseUser] = useState(false);
  const [modalEditUser, setModalEditUser] = useState(false);

  const { user, signOut, verifyUser } = useAuth();
  const formRef = useRef<FormHandles>(null);
  const { createPDCA, listPDCA } = usePDCA();
  const { listUsersCompany } = useTask();
  const { createUser } = useUser();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const timerRef = useRef<number>();

  const userName = user?.name.split(' ');
  const firstName = userName[0];
  const stringAvatar = firstName.substr(0, 1);
  useEffect(() => {
    async function verify(): Promise<void> {
      const updateUser = await verifyUser();
      setModalCloseUser(updateUser);
    }

    verify();
  }, [verifyUser]);

  function handleLogout(): void {
    signOut();
  }

  const handleCreateUser = useCallback(
    async ({ name, email, phone, account }): Promise<void> => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('Digite o nome do usuário'),
          email: Yup.string().email().required('Digite o e-mail do usuário'),
          phone: Yup.string().required('Digite o whatsapp do usuário'),
        });

        await schema.validate(
          { name, email, phone, account },
          { abortEarly: false },
        );
        const dataUser = await createUser({
          name,
          email,
          phone,
          account,
          company_id: user.company_id.id,
        });
        if (dataUser) {
          formRef.current?.reset();
          setModalCreateUser(false);

          await listUsersCompany(user.company_id.id);
          toast.success('Usuário cadastrado com sucesso.');
        } else {
          formRef.current?.reset();
          setModalCreateUser(false);
          toast.error(
            'Verifique se o e-mail já existe ou se a quantidade de usuários contratados acabou.',
          );
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          toast.error('Verifique se os campos digitados estão corretos.');

          formRef.current?.setErrors(errors);
        }
      }
    },
    [user, createUser, listUsersCompany],
  );

  const handleCreatePDCA = useCallback(
    async (data: PDCAFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('Digite o nome da PDCA'),
          description: Yup.string().required('Digite a descrição dessa PDCA'),
        });

        await schema.validate(data, { abortEarly: false });

        const PDCA = {
          name: data.name,
          description: data.description,
          company_id: user.company_id.id,
        };

        createPDCA(PDCA);

        listPDCA();

        formRef.current?.reset();

        setModalClosePdca(false);

        toast.success('PDCA criada com sucesso.');

        clearTimeout(timerRef.current);

        timerRef.current = window.setTimeout(() => {
          window.location.reload();
        }, 3000);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          formRef.current?.setErrors(errors);
        }

        toast.error(
          'Erro ao criar uma PDCA, verificar os campos digitados,se persistir contactar o administrador do sistema',
        );
      }
    },
    [user, createPDCA, listPDCA],
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <>
      <NavBarCustom collapseOnSelect expand="lg" bg="white" variant="light">
        <NavRight>
          <Navbar.Brand>
            <Image src={logoImg} alt="logo" />
          </Navbar.Brand>
        </NavRight>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />

        <Navbar.Collapse id="responsive-navbar-nav">
          <NavCenter>
            <Nav>
              {user && user.account !== 3 ? (
                <>
                  <NavDropdownCustom title="PDCA" id="PDCA">
                    <MenuLink2
                      type="button"
                      onClick={() => {
                        setModalClosePdca(true);
                      }}
                    >
                      Nova PDCA
                    </MenuLink2>

                    <NavDropdown.Divider />

                    <MenuLink to="/dashboard">Pdcas</MenuLink>

                    <NavDropdown.Divider />

                    <MenuLink to="/planoacao">Plano de ação</MenuLink>
                  </NavDropdownCustom>
                </>
              ) : (
                <></>
              )}

              {user.account === 1 ? (
                <NavDropdownCustom title="Usuários" id="Usuários">
                  <MenuLink2
                    type="button"
                    onClick={() => {
                      setModalCreateUser(true);
                    }}
                  >
                    Novo Usuário
                  </MenuLink2>

                  <NavDropdown.Divider />

                  <MenuLink to="/usuarios">Listar Usuários</MenuLink>
                </NavDropdownCustom>
              ) : (
                <></>
              )}
            </Nav>
          </NavCenter>

          <NavLeft>
            <TextUser>{firstName}</TextUser>
            <MenuCustom type="button" onClick={handleClick}>
              <InfoAvatar>
                {user?.avatar ? (
                  <Avatar src={user?.avatar} alt="avatar" />
                ) : (
                  <Avatar>{stringAvatar}</Avatar>
                )}
              </InfoAvatar>
            </MenuCustom>
            <MenuConfig
              id="simple-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItemConfig
                onClick={() => {
                  setModalEditUser(true);
                  handleClose();
                }}
              >
                <Row>
                  <RiShieldUserFill size={25} color="#c489bc" />
                  Meu perfil
                </Row>
              </MenuItemConfig>
              <MenuItemConfig
                onClick={() => {
                  handleClose();
                  handleLogout();
                }}
              >
                <Row>
                  <TiArrowForwardOutline size={24} color="#df0101" />
                  Sair da plataforma
                </Row>
              </MenuItemConfig>
            </MenuConfig>
          </NavLeft>
        </Navbar.Collapse>
      </NavBarCustom>

      {modalClosePdca ? (
        <ModalStyled
          show={modalClosePdca}
          onHide={() => setModalClosePdca(false)}
          labelledby="contained-modal-title-vcenter"
        >
          <div>
            <Modal.Header>
              <h1>
                Criar PDCA
                <FiXCircle size={30} onClick={() => setModalClosePdca(false)} />
              </h1>
            </Modal.Header>

            <Modal.Body>
              <Form ref={formRef} onSubmit={handleCreatePDCA}>
                <Input
                  name="name"
                  icon={FiArrowRightCircle}
                  placeholder="Nome da PDCA"
                  required
                />
                <TextArea
                  rows={5}
                  name="description"
                  icon={FiArrowRightCircle}
                  placeholder="Descrição "
                  required
                />
                <Button type="submit">
                  <FiSave /> Salvar
                </Button>
              </Form>
            </Modal.Body>
          </div>
        </ModalStyled>
      ) : null}

      {modalCreateUser && (
        <ModalStyled
          show={modalCreateUser}
          onHide={() => setModalCreateUser(false)}
        >
          <Modal.Header>
            <h1>
              Criar Usuário
              <FiXCircle size={30} onClick={() => setModalCreateUser(false)} />
            </h1>
          </Modal.Header>
          <Modal.Body>
            <Form ref={formRef} onSubmit={handleCreateUser}>
              <Input
                name="name"
                icon={FiUser}
                placeholder="Nome"
                type="text"
                required
              />

              <Input
                name="email"
                icon={FiMail}
                placeholder="E-mail"
                type="email"
                required
              />

              <Input
                name="phone"
                icon={FaWhatsapp}
                placeholder="Whatsapp"
                type="text"
                required
              />

              <SelectCreateUser name="account" account={user.account} />

              <Button type="submit">
                <FiSave /> Salvar
              </Button>
            </Form>
          </Modal.Body>
        </ModalStyled>
      )}

      {modalEditUser && (
        <ModalStyled
          onHide={() => setModalEditUser(false)}
          show={modalEditUser}
        >
          <CreateEditUser info={user} onClose={() => setModalEditUser(false)} />
        </ModalStyled>
      )}

      {modalCloseUser ? (
        <ModalStyled
          show={modalCloseUser}
          onHide={() => setModalCloseUser(false)}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header>
            <h1> Usuário alterado!</h1>
          </Modal.Header>
          <Modal.Body>
            <div>
              <span className="label">
                Seu usuário foi alterado, será feito logout!
              </span>
            </div>

            <Button onClick={signOut}>
              Sair <FiLogOut size={22} />
            </Button>
          </Modal.Body>
        </ModalStyled>
      ) : null}
    </>
  );
};

export default Header;
