/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect } from 'react';
import { BsCircleFill } from 'react-icons/bs';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

import { useTask } from '../../hooks/task';
import { Div, Legend, Items, Title, Text } from './styles';

const Graphic: React.FC = () => {
  const [andamento, setAndamento] = useState(0);
  const [concluido, setConcluido] = useState(0);
  const [cancelado, setCancelado] = useState(0);
  const [pendente, setPendente] = useState(0);
  const [feedback, setFeedback] = useState(0);
  const { listPlanAction, planAction } = useTask();

  useEffect(() => {
    listPlanAction();
  }, [listPlanAction]);

  useEffect(() => {
    if (planAction) {
      const pendentes = planAction.filter(
        (task) => task.status_task === 'Pendente',
      ).length;
      const andamentos = planAction.filter(
        (task) => task.status_task === 'Andamento',
      ).length;
      const concluidos = planAction.filter(
        (task) => task.status_task === 'Concluído',
      ).length;
      const cancelados = planAction.filter(
        (task) => task.status_task === 'Cancelado',
      ).length;
      const feedbacks = planAction.filter(
        (task) => task.status_task === 'Feedback',
      ).length;
      setFeedback(feedbacks);
      setPendente(pendentes);
      setAndamento(andamentos);
      setConcluido(concluidos);
      setCancelado(cancelados);
    }
  }, [planAction]);

  const data = [
    { name: 'Pendentes', value: pendente },
    { name: 'Andamentos', value: andamento },
    { name: 'Concluidos', value: concluido },
    { name: 'Cancelados', value: cancelado },
    { name: 'Feedback', value: feedback },
  ];

  const COLORS = ['#ff5370', '#456eff', '#0ec716', '#292929', '#692d60'];

  return (
    <Div>
      <ResponsiveContainer>
        <PieChart>
          <Pie
            dataKey="value"
            isAnimationActive={false}
            data={data}
            fill="#957290"
            label
          >
            {data.map((entry, index) => (
              <Cell key={index} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      <Legend>
        <Title>Legenda</Title>
        <Items>
          <BsCircleFill color="#ff5370" />
          <Text>Pendente</Text>
        </Items>

        <Items>
          <BsCircleFill color="#96adff" />
          <Text>Andamento</Text>
        </Items>

        <Items>
          <BsCircleFill color="#1ba600" />
          <Text>Concluído</Text>
        </Items>

        <Items>
          <BsCircleFill color="#957290" />
          <Text>Feedback</Text>
        </Items>

        <Items>
          <BsCircleFill color="#292929" />
          <Text>Cancelado</Text>
        </Items>
      </Legend>
    </Div>
  );
};

export default Graphic;
