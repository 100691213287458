import styled from 'styled-components';
import { lighten } from 'polished';

export const ContentPDCA = styled.div`
  width: 92%;
  height: 100%;
  align-self: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0.875rem auto 1.875rem auto;

  h1 {
    font-family: 'Acme', sans-serif;
    color: #753f6e;
    letter-spacing: 2px;
    border-bottom: 1px solid #ff9800;
    font-weight: bold;
    margin-bottom: 0.625rem;
  }
`;

export const MenuButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-content: right;
  margin-bottom: 20px;

  @media (max-width: 910px) {
    height: 80px;
  }

  > a.linkVoltar {
    background: transparent;
    margin-right: 10px;
    text-decoration: none;
    padding: 5px 10px;
    align-self: center;
    border: 0;
    color: #4a4a4a;
    display: flex;
    transition: color 0.2s;

    &:hover {
      color: ${lighten(0.2, '#4a4a4a')};
    }

    svg {
      margin: auto;
    }
  }
`;
