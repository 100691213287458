import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: stretch;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 750px;
  margin: auto;
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to{
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Image = styled.img`
  width: 400px;
  height: 140px;
  margin-bottom: 5%;

  @media (max-width: 700px) {
    width: 300px;
    height: 100px;
  }
`;
export const Title = styled.h1`
  margin-bottom: 6%;
  color: #957290;
  font-family: 'Acme', sans-serif;
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  animation: ${appearFromLeft} 1s;

  form {
    margin: 5% 0;
    width: 310px;
    text-align: center;
  }
`;

export const Background = styled.div`
  flex: 1;

  @media (max-width: 900px) {
    display: none;
  }
`;
