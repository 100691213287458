/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useCallback, useRef } from 'react';

import Brlocale from 'date-fns/locale/pt-BR';
import { format, intervalToDuration } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { toast } from 'react-toastify';

import TablePagination from '@material-ui/core/TablePagination';
import TableContainer from '@material-ui/core/TableContainer';
import IconButton from '@material-ui/core/IconButton';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import Collapse from '@material-ui/core/Collapse';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import Modal from 'react-bootstrap/Modal';
import Box from '@material-ui/core/Box';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

import {
  BsChatSquareDots,
  FcCollapse,
  FcExpand,
  FiXCircle,
  FiSave,
  FiTrash2,
  AiFillQuestionCircle,
  SiGooglehangoutschat,
  SiWechat,
} from 'react-icons/all';

import { useAuth } from '../../hooks/auth';

import { useJustification } from '../../hooks/justification';

import Textarea from '../TextArea';
import Button from '../Button';

import {
  DivDetails,
  TypographyCustom,
  TableCustom,
  TableCellCustom,
  TableCellHeaderCustom,
  ModalStyled,
  Tdstatus,
  Col1,
  Col2,
  Group,
  TableSortLabelCustom,
  TableFooterCustom,
  TitleDetails,
  DivText,
  ButtonDelete,
} from './styles';

interface Data {
  id: string;
  name: string;
  user_id: {
    id: string;
    name: string;
    email: string;
  };
  pdca_id: {
    id: string;
    name: string;
    description: string;
    user_id: {
      id: string;
    };
  };
  reason: string;
  action: string;
  date_start: Date;
  date_preview: Date;
  date_end: Date;
  status_task:
    | 'Pendente'
    | 'Andamento'
    | 'Concluído'
    | 'Cancelado'
    | 'Feedback'
    | undefined;
  justification_id: {
    user_send: string;
    view: boolean;
    message: string;
    id: string;
  };
}

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => void;
  order: Order;
  orderBy: string;
  // eslint-disable-next-line react/no-unused-prop-types
  rowCount: number;
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

interface Props {
  rows: Data[];
}

type Order = 'asc' | 'desc';

const headCells: HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Tarefa',
  },
  {
    id: 'pdca_id',
    numeric: false,
    disablePadding: false,
    label: 'PDCA',
  },
  {
    id: 'date_preview',
    numeric: false,
    disablePadding: false,
    label: 'Fim previsto',
  },
  {
    id: 'status_task',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'justification_id',
    numeric: false,
    disablePadding: false,
    label: 'Follow-up',
  },
];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T): number {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: {
    [key in Key]: string | number | boolean | object | undefined | null;
  },
  b: {
    [key in Key]: string | number | boolean | object | undefined | null;
  },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number): any {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props: EnhancedTableProps): any {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof Data) => (
    event: React.MouseEvent<unknown>,
  ) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCellHeaderCustom />
        {headCells.map((headCell) => (
          <TableCellHeaderCustom
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <Tooltip title="Ordenar">
              <TableSortLabelCustom
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabelCustom>
            </Tooltip>
          </TableCellHeaderCustom>
        ))}
      </TableRow>
    </TableHead>
  );
}

const EnhancedTable: React.FC<Props> = ({ rows }) => {
  const [addJustificationModal, setAddJustificationModal] = useState(false);
  const [open, setOpen] = useState(false);

  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [page, setPage] = React.useState(0);

  const [orderBy, setOrderBy] = useState<keyof Data>('reason');
  const [taskDetails, setTaskDetails] = useState<Data>();
  const [openTask, setOpenTask] = useState<Data>();
  const [order, setOrder] = useState<Order>('asc');
  const [openId, setOpenId] = useState('');

  const formRefJustification = useRef<FormHandles>(null);

  const numberRows = rows.length;

  const { user } = useAuth();

  const {
    searchListJustification,
    listJustification,
    createJustification,
    deleteJustification,
  } = useJustification();

  const handleClick = (id: string): void => {
    const filterData = rows.filter((row) => row.id === id);

    setTaskDetails(filterData[0]);

    setOpenId(id);

    setOpen(!open);
  };

  const handleChangePage = (event: unknown, newPage: number): void => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenJustificationModal = useCallback(
    async (task: Data): Promise<void> => {
      try {
        setAddJustificationModal(true);
        await searchListJustification(task.id);
      } catch (error) {
        console.log(error);
      }
    },
    [searchListJustification],
  );

  const handleCreateJustification = useCallback(
    async ({ message }) => {
      try {
        if (openTask) {
          const justificationData = await createJustification({
            message,
            pdca_id: openTask.pdca_id.id,
            task_id: openTask.id,
            user_id_receive: user.id,
            user_id_send: user.id,
            user_send: user.name,
          });

          if (justificationData) {
            toast.success('Follow-up criado com sucesso!');
            formRefJustification.current?.reset();
          } else {
            setAddJustificationModal(false);
            toast.error('Erro ao criar follow-up.');
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    [createJustification, user, openTask],
  );

  const handleDeleteJustification = useCallback(
    async (justification_id: string) => {
      try {
        if (openTask && openTask.id) {
          const justificationData = await deleteJustification(
            justification_id,
            openTask.id,
          );

          if (justificationData) {
            setAddJustificationModal(false);
            toast.info('Follow-up excluído com sucesso.');
          } else {
            setAddJustificationModal(false);
            toast.error('Erro ao excluir follow-up.');
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    [deleteJustification, openTask],
  );

  const handleDayPreview = useCallback((date: Date): number => {
    const { days } = intervalToDuration({
      start: new Date(),
      end: new Date(date),
    });

    if (days) {
      return days;
    }
    return 0;
  }, []);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ): void => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <>
      <TableContainer>
        <TableCustom
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row: Data) => {
                return (
                  <React.Fragment key={row.id}>
                    <TableRow tabIndex={-1}>
                      <TableCellCustom>
                        <Tooltip title="Ver tarefa">
                          <IconButton
                            aria-label="expand row"
                            size="medium"
                            onClick={() => handleClick(String(row.id))}
                          >
                            {open ? (
                              row.id === openId ? (
                                <FcCollapse />
                              ) : (
                                <FcExpand />
                              )
                            ) : (
                              <FcExpand />
                            )}
                          </IconButton>
                        </Tooltip>
                      </TableCellCustom>
                      <TableCellCustom align="center">
                        {row.name}
                      </TableCellCustom>
                      <TableCellCustom align="center">
                        {row.pdca_id.name}
                      </TableCellCustom>
                      {handleDayPreview(row.date_preview) >= 3 ||
                      row.status_task === 'Concluído' ||
                      row.status_task === 'Cancelado' ||
                      row.status_task === 'Feedback' ? (
                        <TableCellCustom align="center">
                          {format(new Date(row.date_preview), 'dd/MM/yyyy', {
                            locale: Brlocale,
                          })}
                        </TableCellCustom>
                      ) : (
                        <TableCellCustom align="center" className="alertDate">
                          <p className="alertDate">
                            {format(new Date(row.date_preview), 'dd/MM/yyyy', {
                              locale: Brlocale,
                            })}
                          </p>
                        </TableCellCustom>
                      )}

                      <TableCellCustom align="center">
                        <Tdstatus type={row.status_task}>
                          {row.status_task}
                        </Tdstatus>
                      </TableCellCustom>
                      <TableCellCustom align="center">
                        {row?.justification_id?.user_send !== user.name &&
                        row.justification_id &&
                        !row?.justification_id?.view ? (
                          <Tooltip title="Novo follow-up">
                            <button
                              className="notification"
                              onClick={() => {
                                setOpenTask(row);
                                handleOpenJustificationModal(row);
                              }}
                              type="button"
                            >
                              <SiGooglehangoutschat />
                            </button>
                          </Tooltip>
                        ) : (
                          <Tooltip title="Ver follow-up">
                            <button
                              className="notNotification"
                              onClick={() => {
                                setOpenTask(row);
                                handleOpenJustificationModal(row);
                              }}
                              type="button"
                            >
                              <SiWechat size={30} />
                            </button>
                          </Tooltip>
                        )}
                      </TableCellCustom>
                    </TableRow>
                    {openId === row.id ? (
                      <TableRow>
                        <TableCellCustom
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          colSpan={6}
                        >
                          <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box margin={1}>
                              <TypographyCustom>
                                <TitleDetails>Detalhes</TitleDetails>
                                <hr />
                              </TypographyCustom>

                              <DivDetails>
                                <Grid container xs={12}>
                                  <Grid item xs={5} sm={5}>
                                    <DivText>
                                      <strong>PDCA:</strong>
                                      <span>{taskDetails?.pdca_id.name} </span>
                                    </DivText>

                                    <DivText>
                                      <strong>Tarefa:</strong>
                                      <span>{taskDetails?.name} </span>
                                    </DivText>

                                    <DivText>
                                      <strong>Status:</strong>
                                      <span>{taskDetails?.status_task} </span>
                                    </DivText>

                                    <DivText>
                                      <strong>Início:</strong>
                                      <span>
                                        {taskDetails?.date_start ? (
                                          format(
                                            new Date(taskDetails?.date_start),
                                            'dd/MM/yyyy',
                                            {
                                              locale: Brlocale,
                                            },
                                          )
                                        ) : (
                                          <AiFillQuestionCircle />
                                        )}
                                      </span>
                                    </DivText>

                                    <DivText>
                                      <strong>Termino previsto:</strong>
                                      <span>
                                        {taskDetails?.date_preview ? (
                                          format(
                                            new Date(taskDetails?.date_preview),
                                            'dd/MM/yyyy',
                                            {
                                              locale: Brlocale,
                                            },
                                          )
                                        ) : (
                                          <AiFillQuestionCircle />
                                        )}
                                      </span>
                                    </DivText>
                                  </Grid>

                                  <Grid item xs={7} sm={7}>
                                    <DivText>
                                      <strong>Termino oficial:</strong>
                                      <span>
                                        {taskDetails?.date_end ? (
                                          format(
                                            new Date(taskDetails?.date_end),
                                            'dd/MM/yyyy',
                                            {
                                              locale: Brlocale,
                                            },
                                          )
                                        ) : (
                                          <AiFillQuestionCircle />
                                        )}
                                      </span>
                                    </DivText>

                                    <DivText>
                                      <strong>Motivo:</strong>
                                      <span>{taskDetails?.reason} </span>
                                    </DivText>

                                    <DivText>
                                      <strong>Ação:</strong>
                                      <span>{taskDetails?.action} </span>
                                    </DivText>
                                  </Grid>
                                </Grid>
                              </DivDetails>
                            </Box>
                          </Collapse>
                        </TableCellCustom>
                      </TableRow>
                    ) : (
                      <></>
                    )}
                  </React.Fragment>
                );
              })}
          </TableBody>
        </TableCustom>
      </TableContainer>

      <TableFooterCustom>
        <TablePagination
          rowsPerPageOptions={[
            15,
            25,
            50,
            75,
            100,
            { value: numberRows, label: 'Todos' },
          ]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage="Linhas por página"
          backIconButtonText="Página anterior"
          nextIconButtonText="Próxima página"
        />
      </TableFooterCustom>

      {addJustificationModal && (
        <ModalStyled
          size="lg"
          show={addJustificationModal}
          onHide={() => setAddJustificationModal(false)}
        >
          <Modal.Header>
            <h1>
              Follow-up
              <FiXCircle
                size={30}
                onClick={() => setAddJustificationModal(false)}
              />
            </h1>
          </Modal.Header>

          <Modal.Body>
            <Group>
              <Col1>
                <Form
                  ref={formRefJustification}
                  onSubmit={handleCreateJustification}
                >
                  <Textarea
                    name="message"
                    icon={BsChatSquareDots}
                    placeholder="Digite seu follow-up"
                    required
                  />
                  <Button type="submit">
                    <FiSave /> Salvar
                  </Button>
                </Form>
              </Col1>

              <Col2>
                <h3>Justificativas</h3>
                {listJustification &&
                  listJustification.map((justification) => (
                    <DivText key={justification.id}>
                      <DivText>
                        <strong>
                          {justification.user_send} -{' '}
                          <span>
                            {format(
                              zonedTimeToUtc(
                                justification.created_at,
                                'America/Los_Angeles',
                              ),
                              'dd/MM/yyyy HH:mm',
                              {
                                locale: Brlocale,
                              },
                            )}
                          </span>
                          {justification.user_id_send.id === user.id ? (
                            <ButtonDelete
                              onClick={() => {
                                handleDeleteJustification(justification.id);
                              }}
                              type="button"
                            >
                              <FiTrash2 />
                            </ButtonDelete>
                          ) : (
                            <DivText />
                          )}
                        </strong>
                        <p>{justification.message}</p>
                      </DivText>

                      <hr />
                    </DivText>
                  ))}
              </Col2>
            </Group>
          </Modal.Body>
        </ModalStyled>
      )}
    </>
  );
};

export default EnhancedTable;
