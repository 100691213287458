import { createGlobalStyle } from 'styled-components';

import 'react-toastify/dist/ReactToastify.css';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
    font-size:16px;
    @media (max-width: 800px) {
    font-size:12px;
  }
  }

  body {
    color: #292929;
    -webkit-font-smoothing: antialiased;
    font-size:0.875rem;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    background-color: #f2f2f2;
  }

  button {
    cursor: pointer;
    font-family: 'Acme Slab', sans-serif;
    font-size: 1.15rem;
  }

  strong, span,input,select,p,a,label,div,td,tr,th,textarea,li,ul,option,table {
    font-weight: 300;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  }

  h1, h2, h3, h4, h5, h6{
    font-size: 2.25rem;
    font-family: 'Acme', sans-serif !important;
  }

  strong, span,p,a,label,div,td,tr,th,li,ul,select,input,option {
    font-size:1rem;
  }



`;
