import styled from 'styled-components';

export const Archive = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 0;
  cursor: pointer;

  div {
    justify-content: center;
    align-items: center;

    label {
      width: 36px;
      height: 38px;
      position: absolute;
      bottom: 12px;
      right: 2px;
      background: #ff9800;
      border-radius: 50%;
      color: #fff;
      text-align: center;
      font-weight: bold;
      box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.4);
      margin: auto;
      cursor: pointer;
      font-size: 1.75rem;

      svg {
        width: 20px !important;
        height: 20px !important;
      }
    }

    img {
      width: 170px;
      height: 170px;
      border-radius: 50%;
    }
  }
`;
