/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unused-prop-types */
import React, { useState, useCallback, useEffect } from 'react';
import { format } from 'date-fns';
import Brlocale from 'date-fns/locale/pt-BR';

import {
  TableBody,
  TableHead,
  TableRow,
  TableContainer,
  TablePagination,
  Tooltip,
} from '@material-ui/core';

import { FiEdit2, RiUserFollowFill, RiUserUnfollowFill } from 'react-icons/all';

import { useAuth } from '../../hooks/auth';
import { useTask } from '../../hooks/task';
import CreateEditUser from '../CreateEditUser';

import {
  TableCustom,
  TableCellCustom,
  TableCellHeaderCustom,
  TableSortLabelCustom,
  ModalStyled,
  TableFooterCustom,
  Title,
  ButtonEdit,
  DivAvatar,
} from './styles';

interface Data {
  id: string;
  name: string;
  email: string;
  phone: string;
  account: number;
  status_user: boolean;
  avatar: string;
  created_at: Date;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T): number {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string | boolean | any },
  b: { [key in Key]: number | string | boolean | any },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number): any {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  {
    id: 'avatar',
    numeric: false,
    disablePadding: false,
    label: 'Avatar',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Nome',
  },
  { id: 'email', numeric: false, disablePadding: false, label: 'E-mail' },
  { id: 'phone', numeric: true, disablePadding: false, label: 'Telefone' },
  {
    id: 'created_at',
    numeric: true,
    disablePadding: false,
    label: 'Data de Criação',
  },
];

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps): any {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof Data) => (
    event: React.MouseEvent<unknown>,
  ) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCellHeaderCustom
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <Tooltip title="Ordenar">
              <TableSortLabelCustom
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabelCustom>
            </Tooltip>
          </TableCellHeaderCustom>
        ))}
      </TableRow>
    </TableHead>
  );
}

const ListUsers: React.FC = () => {
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof Data>('id');

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const [modalEditUser, setModalEditUser] = useState(false);
  const { listUsersCompany, listUsers } = useTask();
  const [dataUser, setDataUser] = useState();

  const { user } = useAuth();

  useEffect(() => {
    listUsersCompany(user.company_id.id);
  }, [listUsersCompany, user]);

  const handleEnterEditUser = useCallback(async (data) => {
    setModalEditUser(true);
    setDataUser(data);
  }, []);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ): void => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number): void => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Title>Usuários</Title>
      <TableContainer>
        <TableCustom
          aria-labelledby="Usuários"
          size="medium"
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={listUsers.length}
          />
          <TableBody>
            {stableSort(listUsers, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row: Data) => {
                return (
                  <TableRow tabIndex={-1} key={row.id}>
                    <TableCellCustom>
                      {row.avatar ? (
                        <DivAvatar>
                          <img src={row.avatar} alt="Avatar" />
                        </DivAvatar>
                      ) : row.status_user ? (
                        <RiUserFollowFill size={30} color="#b89fdc" />
                      ) : (
                        <RiUserUnfollowFill size={30} color="#ff4d4d" />
                      )}
                    </TableCellCustom>

                    <TableCellCustom align="center">{row.name}</TableCellCustom>

                    <TableCellCustom align="center">
                      {row.email}
                    </TableCellCustom>

                    <TableCellCustom align="center">
                      {row.phone}
                    </TableCellCustom>

                    <TableCellCustom align="center">
                      {format(new Date(row.created_at), 'dd/MM/yyyy', {
                        locale: Brlocale,
                      })}
                    </TableCellCustom>
                    <Tooltip title="Tipo usuário">
                      {row.account === 1 ? (
                        <TableCellCustom align="center">
                          <p className="diretor">Diretor</p>
                        </TableCellCustom>
                      ) : row.account === 2 ? (
                        <TableCellCustom align="center">
                          <p className="gestor">Gestor</p>
                        </TableCellCustom>
                      ) : (
                        <TableCellCustom align="center">
                          <p className="colaborador">Colaborador</p>
                        </TableCellCustom>
                      )}
                    </Tooltip>

                    <Tooltip title="Status usuário">
                      {row.status_user ? (
                        <TableCellCustom align="center">
                          <p className="ativo">Ativo</p>
                        </TableCellCustom>
                      ) : (
                        <TableCellCustom align="center">
                          <p className="inativo">Desativado</p>
                        </TableCellCustom>
                      )}
                    </Tooltip>
                    <TableCellCustom align="center">
                      <Tooltip title="Editar usuário">
                        <ButtonEdit
                          onClick={() => {
                            handleEnterEditUser(row);
                          }}
                          type="button"
                        >
                          <FiEdit2 />
                        </ButtonEdit>
                      </Tooltip>
                    </TableCellCustom>
                  </TableRow>
                );
              })}
          </TableBody>
        </TableCustom>
      </TableContainer>
      <TableFooterCustom>
        <TablePagination
          rowsPerPageOptions={[
            15,
            25,
            50,
            100,
            { value: listUsers.length, label: 'Todos' },
          ]}
          count={listUsers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage="Linhas por página"
          backIconButtonText="Página anterior"
          nextIconButtonText="Próxima página"
        />
      </TableFooterCustom>

      {modalEditUser && (
        <ModalStyled
          show={modalEditUser}
          onHide={() => setModalEditUser(false)}
        >
          <CreateEditUser
            info={dataUser || user}
            onClose={() => setModalEditUser(false)}
          />
        </ModalStyled>
      )}
    </>
  );
};

export default ListUsers;
