import styled, { css } from 'styled-components';
import { lighten } from 'polished';
import Modal from 'react-bootstrap/Modal';
import {
  Toolbar,
  TableFooter,
  TableSortLabel,
  TableCell,
  Table,
  Typography,
  TextField,
  Avatar,
  Tooltip,
} from '@material-ui/core';

interface DivProps {
  type?:
    | 'Pendente'
    | 'Andamento'
    | 'Concluído'
    | 'Cancelado'
    | 'Feedback'
    | undefined;
}

const DivTypeVariations = {
  Pendente: css`
    background: #fff;
    color: #ff5370;
    border: 2px solid #ff5370;
    padding: 5px;
    border-radius: 0.5rem;
  `,
  Andamento: css`
    background: #fff;
    color: #456eff;
    border: 2px solid #456eff;
    padding: 5px;
    border-radius: 0.5rem;
  `,
  Concluído: css`
    background: #fff;
    color: #0ec716;
    border: 2px solid #0ec716;
    padding: 5px;
    border-radius: 0.5rem;
  `,
  Feedback: css`
    background: #fff;
    color: #692d60;
    border: 2px solid #692d60;
    padding: 5px;
    border-radius: 0.5rem;
  `,
  Cancelado: css`
    background: #fff;
    color: #000;
    border: 2px solid #000;
    padding: 5px;
    border-radius: 0.5rem;
  `,
};

export const AvatarStyled = styled(Avatar)`
  margin: auto 5px;
  width: 1.85rem !important;
  height: 1.85rem !important;
  background-color: #692d60 !important;
  cursor: pointer;
`;

export const AvatarStyled2 = styled(Avatar)`
  margin: auto;
  width: 4.15rem !important;
  height: 4.15rem !important;
  background-color: #9a67b5 !important;
  border: 2px #fff solid;
`;

export const TextFieldCustom = styled(TextField)`
  .MuiTextField-root {
    background-color: #000 !important;
    color: #fff123;
  }
`;

export const ToolbarCustom = styled(Toolbar)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-content: right;
  margin-bottom: 10px;
`;

export const DivToolbar = styled.div`
  display: flex;
  margin-left: 73%;
  width: 100%;

  @media (max-width: 1340px) {
    margin-left: 68%;
  }
  @media (max-width: 1190px) {
    margin-left: 62%;
  }
  @media (max-width: 1030px) {
    margin-left: 58%;
  }
  @media (max-width: 650px) {
    margin-left: 44%;
  }
  @media (max-width: 430px) {
    margin-left: 18%;
  }
`;

export const ButtonMenu = styled.button`
  background: #957290;
  margin-left: 0.625rem;
  margin-top: 8px;
  padding: 6px 12px;
  align-self: center;
  border: 0;
  border-radius: 4px;
  color: #fff;
  display: flex;
  transition: 0.2s;

  &:hover {
    background: ${lighten(0.2, '#5b437a')};
  }

  svg {
    margin-right: 4px;
    width: 24px;
    height: 24px;
  }

  .limparFiltro {
    border: 0;
    border-radius: 5px;
    background: #ff233d;
    color: #fff;
  }
`;

export const Title = styled.h1`
  color: #5b437a;
  font-weight: bold;
  margin-left: 0.5rem;
  margin-bottom: 0.75rem;

  span {
    color: #262626;
    letter-spacing: 1px;
    font-size: 2.25rem;
    font-family: 'Acme', sans-serif;
  }
`;

export const ModalStyledFilter = styled(Modal)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  max-width: 98vw;

  div {
    border-radius: 10px;
    background: #f3f3f3;
    justify-content: center;
    align-items: center;
  }

  h1 {
    color: #957290;
    font-weight: 500;
    border: 0;
    margin-top: 4px;

    svg {
      position: absolute;
      right: 0.625rem;
      top: 0.625rem;
      color: #ff9000;
      cursor: pointer;
    }
  }

  Form {
    max-width: 320px;
    width: 100%;
    display: flex;
    flex-direction: column;

    @media (max-width: 800px) {
      max-width: 200px;
    }
  }
`;

export const TableFooterCustom = styled(TableFooter)`
  background: #fff;
  width: 100%;
  margin: auto;
`;

export const CreateEditGroup = styled.div`
  display: flex;
  margin-top: 0.75rem;
  max-width: 100vw !important;

  @media (max-width: 770px) {
    flex-direction: column;
  }
`;

export const Label = styled.span`
  padding: 0.625rem;
  color: #5b437a;
  margin-bottom: 4px;
`;

export const GroupOne = styled.div`
  padding: 0.75rem;
  width: 280px;
  flex-direction: column;

  .MuiAutocomplete-endAdornment {
    background-color: #fff !important;
    margin: auto;
    border: 0 !important;
  }
  .MuiAutocomplete-inputRoot {
    width: 100%;
    height: 2rem;
    border-radius: 10px;
    cursor: pointer;
    color: #666360 !important;
    flex: 1;
    background: #fff !important;
    border: 0 !important;
    padding: 0.15rem !important;
    font-size: 1rem;
    margin-top: 10px;
    margin-left: 5px;
    font-weight: 500 !important;

    option {
      cursor: pointer;
    }
  }

  .MuiTextField-root .Mui-focused .MuiAutocomplete-inputFocused {
    color: #666360 !important;
    border-color: #666360 !important;
    margin: auto;
    font-weight: 500 !important;
  }
`;

export const GroupTwo = styled.div`
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
`;

export const TableSortLabelCustom = styled(TableSortLabel)`
  background: #6fa7c7;
  color: #ffffff !important;
  border-radius: 1rem;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem !important;

  svg {
    margin: auto;
    width: 26px;
    height: 26px;
    color: #6e005e !important;
  }
`;

export const Tdstatus = styled.div<DivProps>`
  display: flex;
  justify-content: center;
  cursor: pointer;
  ${(props) => DivTypeVariations[props.type || 'Pendente']}
`;

export const TableCustom = styled(Table)`
  min-width: 750px;
  width: 100% !important;
  margin: auto;
  background-color: #fff;
`;

export const TableCellCustom = styled(TableCell)`
  font-weight: bold !important;
  font-size: 1rem !important;

  @keyframes light {
    from {
      background: #ffc657;
      color: #000;
      border-radius: 0.5rem;
    }
    to {
      background: white;
      border-radius: 0.5rem;
    }
  }

  .alertDate {
    background: #ffc657 !important;
    border-radius: 10px;
    padding: 0.5rem;
  }

  button {
    border: 0;
    padding: 3px 8px;
    background: transparent;
    border-radius: 4px;
    margin-right: 2px;

    svg {
      transition: 0.2s;
      color: #555;
      &:hover {
        background: ${lighten(0.2, '#757575')};
      }
    }
  }

  button.notification {
    padding: 0.5rem;
    svg {
      animation: light 1s alternate infinite ease-in-out;
      margin: auto;
      width: 26px;
      height: 26px;
      color: #6e005e !important;
    }
  }

  button.notNotification {
    svg {
      transition: 0.2s;
      margin: auto;
      width: 26px;
      height: 26px;
      color: #ff9800 !important;
    }
  }
`;

export const TableCellHeaderCustom = styled(TableCell)`
  background: #878787;
  color: #ffffff !important;
  border-radius: 1rem;
  justify-content: center !important;
  align-items: center;
  padding: 0.4rem !important;
`;

export const DivDetails = styled.div`
  margin: auto;
  flex-direction: column;

  strong {
    font-size: 1rem !important;
    color: #636363 !important;
    margin: 0.75rem !important;
  }

  span {
    font-size: 1rem !important;
    text-align: justify !important;
    svg {
      color: #6e005e !important;
      width: 22px !important;
      height: 22px !important;
    }
  }
`;

export const Items = styled.div``;

export const ButtonShare = styled.button`
  background: #96adff !important;
  color: #ffffff;
  border-radius: 0.625rem;
  border: none;
  padding: 0.35rem;

  svg {
    color: #fff !important;
  }
`;

export const ButtonEdit = styled.button`
  background: #780066 !important;
  color: #ffffff;
  border-radius: 0.625rem;
  border: none;
  padding: 0.35rem;

  svg {
    color: #fff !important;
  }
`;

export const TypographyCustom = styled(Typography)`
  color: #957290;

  hr {
    border: 1px solid #878787 !important;
    background-color: #878787 !important;
    width: 100% !important;
    margin: auto;
  }

  h3 {
    color: #878787;
    font-size: 1.5rem !important;
  }
`;

export const RowCustom = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const ButtonDeleteModal = styled.button`
  position: absolute;
  background: #ff0d0d;
  color: #ffffff;
  border-radius: 0.625rem;
  border: none;
  right: 84%;
  top: 0.625rem;
  padding: 0.625rem;
`;

export const ModalStyled = styled(Modal)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 98vw;

  p {
    font-size: 1rem;
    color: #363636;
  }

  div {
    background: #f3f3f3;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.2rem;
    border-radius: 10px;

    h1 {
      color: #957290;
      border: 0;
      margin-top: 0.5rem;

      svg {
        position: absolute;
        right: 0.75rem;
        top: 0.75rem;
        color: #ff9000;
        cursor: pointer;
      }
    }

    button.desativar {
      width: 120px;
      background: #ff2121;
      border: none;
      color: #fff;
      border-radius: 0.625rem;
      margin: 1rem;
      padding: 0.25rem;

      svg {
        margin: auto;
        width: 26px;
        height: 26px;
      }
    }

    button.ativar {
      width: 120px;
      background: #33691e;
      border: none;
      color: #fff;
      border-radius: 0.625rem;
      margin: 1rem;
      padding: 0.25rem;

      svg {
        margin: auto;
        width: 26px;
        height: 26px;
      }
    }

    button.cancelar {
      width: 120px;
      background: #957290;
      border: none;
      color: #fff;
      border-radius: 0.625rem;
      margin: 1rem;
      padding: 0.25rem;

      svg {
        margin: auto;
        width: 26px;
        height: 26px;
      }
    }
  }
`;

export const Group = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1.25rem;

  strong {
    font-weight: bold;
    color: #404040;
    padding-bottom: 4px;
  }

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export const TitleGroup = styled.h3`
  color: #39215b;
  font-size: 1.125rem;
  margin-bottom: 8px;
`;

export const Col2 = styled.div`
  padding: 0.625rem;
  width: 410px;
  height: 360px;
  margin-left: 0.75rem;
  overflow-y: auto;
  overflow-x: hidden;
  display: block;

  @media (max-width: 705px) {
    width: 280px;
    min-height: 0;
    margin: 0;
    overflow: auto;
    padding: 15px;
  }
`;

export const ButtonDelete = styled.button`
  width: 1.75rem;
  height: 1.75rem;
  border: 0;
  padding: 1px 4px;
  background: #b50012;
  color: #fff;
  border-radius: 4px;
  margin-left: 4%;
`;

export const Rows = styled.div`
  margin-top: 0.625rem;

  hr {
    margin-top: 0.5rem;
    border-color: ${lighten(0.1, '#a020f0')};
  }
`;

export const TextRows = styled.div`
  font-weight: bold;
  color: #404040;
  font-size: 1rem !important;

  span {
    color: #a1a1a1;
    font-size: 0.75rem;
  }

  p {
    color: #555555;
    margin: 5px 0;
    word-break: break-all;
  }
`;

export const Col1 = styled.div`
  display: block !important;
  align-items: center;
  justify-content: center;

  textarea {
    display: block;
    color: #555;
    width: 100%;

    &::placeholder {
      color: #999999;
      font-size: 0.875rem;
    }
  }
`;

export const Fieldset = styled.fieldset`
  margin-top: 2rem;
  min-inline-size: auto;
  border: 0;
`;

export const Field = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const FieldGroup = styled.div`
  flex: 1;
  display: flex;

  @media (max-width: 950px) {
    flex-direction: column;
  }
`;

export const GroupForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .MuiAutocomplete-inputRoot {
    width: 200px !important;
    border-radius: 10px;
    color: #666360 !important;
    background: #fff !important;
    border: 0 !important;
    padding: 0.15rem !important;
    font-size: 1rem;

    option {
      cursor: pointer;
    }
  }

  .MuiAutocomplete-endAdornment {
    background-color: #fff !important;
    margin: auto;
  }
`;

export const GroupCheck = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 5px 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #edf7fe;
`;

export const ButtonSearch = styled.button`
  margin-top: 1rem;
  width: 50%;
  height: 40px;
  border-radius: 5px;
  background: #75a2ff;
  border: 1px solid #75a2ff;
  color: #fff;
  transition: 0.2s;
  &:hover {
    background: #fff;
    color: #75a2ff;
    border: 1px solid #75a2ff;
  }
`;

export const BuscaTask = styled.div`
  display: flex;
  flex-direction: collumn;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.625rem;
  svg {
    width: 1.75rem;
    height: 1.75rem;
  }

  form {
    display: flex;
    align-items: center;

    @media (max-width: 1033px) {
      display: flex;
      flex-direction: column;
    }
  }
  @media (max-width: 1033px) {
    display: flex;
    flex-direction: column;
  }
`;

export const TooltipStyled = styled(Tooltip)`
  background-color: transparent;
  align-items: center;
`;

export const DivTooltip = styled.div`
  margin: auto;
  width: 100%;
`;

export const Text = styled.p`
  font-size: 1.1rem !important;
  margin: auto;
  color: #fff;
  width: 50%;
`;

export const TextEmail = styled.p`
  margin: 0.625rem;
  color: #fff;
`;
