import styled from 'styled-components';

import { Link } from 'react-router-dom';

import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Modal from 'react-bootstrap/Modal';
import { Form as Unform } from '@unform/web';

export const NavBarCustom = styled(Navbar)`
  height: 58px;
  border-radius: 0 0 4px 4px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;

  @media (max-width: 1000px) {
    height: auto;
  }
`;

export const MenuLink = styled(Link)`
  margin: 0 !important;
  color: #6e6e6e !important;
  text-decoration: none !important;
  border: none !important;
`;

export const MenuLink2 = styled.button`
  margin: auto;
  color: #6e6e6e !important;
  text-decoration: none !important;
  border: none !important;
  border: 0;
  background-color: transparent;
  font-size: 1rem !important;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif !important;
`;

export const NavLeft = styled.div`
  margin-left: 42%;
  text-align: center;
  align-items: center;
  display: flex;

  @media (max-width: 990px) {
    margin: auto;
    width: 96%;
  }
`;

export const TextUser = styled.span`
  color: #4f4f4f;
  margin: 0.5rem;

  @media (max-width: 1000px) {
    display: none;
  }
`;

export const NavCenter = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  margin: auto;

  @media (max-width: 990px) {
    display: inline;
  }
`;

export const NavRight = styled.div`
  text-align: center;
  margin-left: 0;
  display: flex;
`;

export const MenuCustom = styled.button`
  background-color: transparent;
  border: none;
  align-items: center;
  margin: auto;

  @media (max-width: 1000px) {
    margin: auto;
    align-items: center;
    width: 96%;
  }

  p {
    font-size: 1rem;
    color: #000;
    margin: auto;
  }

  svg {
    height: 34px;
    width: 34px;
    color: #780066;
    margin-left: 0.625rem;

    @media (max-width: 560px) {
      height: 30px;
      width: 30px;
      margin: 2px;
    }

    &:hover {
      color: #a35297;
    }
  }
`;

export const NavDropdownCustom = styled(NavDropdown)`
  a {
    padding: 1px 5px;
    border-bottom: 1px #ff9800 solid;
    margin: 0 0.875rem;
  }
  @media (max-width: 1000px) {
    margin: auto;
    align-items: center;
  }
`;

export const MenuConfig = styled(Menu)`
  align-items: center;
  margin-top: 1rem;
  @media (max-width: 1000px) {
    margin-left: 40% !important;
  }
`;

export const MenuItemConfig = styled(MenuItem)`
  margin: auto;
  align-items: center;
`;

export const Image = styled.img`
  display: flex;
  flex-direction: column;
  height: 3.5rem;
  margin: auto;
`;

export const InfoAvatar = styled.div`
  display: flex;
  border: 3px solid #957290;
  border-radius: 50%;
  text-align: center;
  align-items: center;
  margin: auto;

  @media (max-width: 1000px) {
    border: none;
    border-radius: 0;
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  .MuiAvatar-root {
    margin: auto;

    @media (max-width: 1000px) {
      margin: 0.35rem auto auto auto;
    }
  }
`;

export const Row = styled.div``;

export const ModalStyled = styled(Modal)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 98vw;

  div {
    background: #f3f3f3;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    width: 100%;

    span.label {
      padding: 0.5rem;
      color: #5b437a;
    }
  }

  h1 {
    color: #957290;
    border: 0;
    margin-top: 0.5rem;

    svg {
      position: absolute;
      right: 0.75rem;
      top: 0.75rem;
      color: #ff9000;
      cursor: pointer;
    }
  }
`;

export const Form = styled(Unform)`
  margin: auto;
  width: 90% !important;
  display: flex;
  padding: 1rem;
  flex-direction: column;
`;
