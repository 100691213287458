import React, {
  useRef,
  useEffect,
  SelectHTMLAttributes,
  useState,
} from 'react';

import { useField } from '@unform/core';

import { Content } from './styles';
import { useTask } from '../../hooks/task';

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  name: string;
  option?: SelectRequest[];
  filter?: boolean;
  selectTask?: boolean;
}

interface SelectRequest {
  value: string;
  label: string;
}

const SelectPersonal: React.FC<SelectProps> = ({
  name,
  option,
  filter,
  selectTask,
  ...rest
}) => {
  const selectRef = useRef(null);
  const [options, setOptions] = useState<SelectRequest[]>([]);
  const { fieldName, defaultValue, registerField } = useField(name);
  const [nameSelect, setNameSelect] = useState('');
  const { listUsers } = useTask();

  const statusLoad: SelectRequest[] = [
    { value: 'Pendente', label: 'Pendente' },
    { value: 'Andamento', label: 'Andamento' },
    { value: 'Concluído', label: 'Concluído' },
    { value: 'Feedback', label: 'Feedback' },
    { value: 'Cancelado', label: 'Cancelado' },
  ];

  useEffect(() => {
    if (defaultValue && defaultValue.id) {
      setNameSelect(defaultValue.id);
    }

    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: 'value',
    });
  }, [fieldName, registerField, defaultValue]);

  function loadOptions(): void {
    if (option) {
      if (filter) {
        setOptions([{ label: 'Selecione o responsável', value: 'Todos' }]);
      }
      if (selectTask) {
        setOptions([{ label: '', value: '' }]);
      }
      listUsers.map(async (list) => {
        setOptions((state) => [...state, { label: list.name, value: list.id }]);
      });
    } else {
      setOptions(statusLoad);
    }
  }

  useEffect(() => {
    loadOptions();
    // eslint-disable-next-line
  }, [option]);

  return (
    <Content>
      <select
        defaultValue={nameSelect}
        value={nameSelect}
        onChange={(e) => {
          setNameSelect(e.target.value);
        }}
        ref={selectRef}
        placeholder="Selecionar"
        {...rest}
      >
        {options &&
          options.map((status) => (
            <option key={status.value} value={status.value}>
              {status.label}
            </option>
          ))}
      </select>
    </Content>
  );
};

export default SelectPersonal;
