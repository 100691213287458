import React, { InputHTMLAttributes, useEffect, useRef, useState } from 'react';
import { useField } from '@unform/core';

import { Container } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  textname: string;
}

const CheckboxReset: React.FC<InputProps> = ({ name, textname, ...rest }) => {
  const [state, setState] = useState(false);
  const [result, setResult] = useState('false');
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  function handleChangeStatus(): void {
    if (state) {
      setState(false);
      setResult('false');
    } else {
      setState(true);
      setResult('true');
    }
  }

  return (
    <Container>
      <div>
        <input
          type="checkbox"
          value={result}
          onChange={handleChangeStatus}
          checked={state}
          ref={inputRef}
          {...rest}
        />
        <span>{textname}</span>
      </div>
    </Container>
  );
};

export default CheckboxReset;
