import axios from 'axios';

const token = sessionStorage.getItem('@SimplesPlan:token');
const id = sessionStorage.getItem('@SimplesPlan:id');

const api = axios.create({
  // baseURL: 'http://localhost:3334',
  baseURL: 'https://api.simplesplan.com.br',
});

api.interceptors.request.use(async (config) => {
  if (token) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token}`;
    // eslint-disable-next-line no-param-reassign
    config.headers.Id = id;
  }
  return config;
});

export default api;
