import styled from 'styled-components';
import { lighten } from 'polished';
import {
  Table,
  TableCell,
  TableFooter,
  TableSortLabel,
} from '@material-ui/core';

import Modal from 'react-bootstrap/Modal';

export const ModalStyled = styled(Modal)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 98vw;

  div {
    background: #f3f3f3;
    margin-bottom: 0.2rem;
    justify-content: center;
    align-items: center;
    border-radius: 1rem !important;

    h1 {
      color: #753f6e;
      border: 0;
      margin-top: 0.5rem;
      text-align: center !important;
      align-items: center !important;

      svg {
        position: absolute;
        right: 0.75rem;
        top: 0.75rem;
        color: #ff9000;
        cursor: pointer;
      }
    }

    button.save {
      width: 100%;
      height: 40px;
      background: #97ccc5;
      border-radius: 8px;
      color: #ffffff !important;
      font-weight: bold;
      font-size: 1.1rem;
      border: 0;
      transition: 0.2s;
      margin-top: 0.5rem;
      cursor: pointer;

      &:hover {
        background: #6495ed;
      }
    }

    Form {
      max-width: 300px;
      width: 98%;
      display: flex;
      flex-direction: column;
      margin-top: 0.75rem;
      margin-bottom: 0.75rem;
    }
  }
`;

export const Title = styled.h1`
  color: #5b437a;
  font-weight: bold;
  margin-left: 0.5rem;
  margin-bottom: 0.95rem;
  border-bottom: none !important;
`;

export const TableFooterCustom = styled(TableFooter)`
  width: 100% !important;
  margin: auto;
  background-color: #fff;
`;

export const TableCustom = styled(Table)`
  min-width: 750px;
  width: 100% !important;
  margin: auto;
  background-color: #fff;
`;

export const DivAvatar = styled.div`
  justify-content: center;
  align-items: center;
  margin: auto;
  cursor: pointer;

  img {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    margin: auto;
  }
`;

export const TableCellCustom = styled(TableCell)`
  font-weight: bold !important;
  font-size: 1rem !important;

  p {
    color: #fff;
    padding: 2px 3px;
    border-radius: 5px;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
  }

  p.diretor {
    color: #9c15a1;
  }
  p.gestor {
    color: #23c3e8;
  }
  p.colaborador {
    color: #ff9800;
  }

  p.ativo {
    background: #5ff582;
  }
  p.inativo {
    background: #ff4d4d;
  }

  svg {
    margin: auto;
    width: 26px;
    height: 26px;
    color: #db0000;
  }
`;

export const ButtonEdit = styled.button`
  border: 0;
  padding: 0.35rem 0.5rem;
  background: #9292a6;
  transition: 0.2s;
  border-radius: 0.5rem;

  &:hover {
    background: ${lighten(0.1, '#9292a6')};
  }

  svg {
    width: 1rem;
    height: 1rem;
    margin: auto;
    color: #fff;
  }
`;
export const TableCellHeaderCustom = styled(TableCell)`
  background: #878787;
  color: #ffffff !important;
  border-radius: 1rem;
  justify-content: center !important;
  align-items: center;
  padding: 0.4rem !important;
`;

export const TableSortLabelCustom = styled(TableSortLabel)`
  color: #ffffff !important;
  border-radius: 1rem;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem !important;

  svg {
    margin: auto;
    width: 26px;
    height: 26px;
    color: #6e005e !important;
  }
`;
