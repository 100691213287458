import React from 'react';
import { Switch, BrowserRouter } from 'react-router-dom';

import Route from './Route';

import SignInEmail from '../pages/SigninEmail';
import SigninPassword from '../pages/SigninPassword';
import Info from '../pages/Home/Info';
import ViewPDCA from '../pages/Home/ViewPDCA';
import User from '../pages/Home/User';
import PlanAction from '../pages/Home/PlanAction';

const Routes: React.FC = () => {
  const routes = (
    <Switch>
      <Route path="/" exact component={SignInEmail} isPrivate={false} />
      <Route
        path="/signinpassword"
        component={SigninPassword}
        isPrivate={false}
      />

      <Route path="/dashboard" component={Info} isPrivate admin />
      <Route path="/acessarpdca" component={ViewPDCA} isPrivate admin />
      <Route path="/usuarios" component={User} isPrivate admin />
      <Route path="/planoacao" component={PlanAction} isPrivate />
    </Switch>
  );

  return <BrowserRouter>{routes}</BrowserRouter>;
};

export default Routes;
