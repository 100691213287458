import React, { useRef, useState, useEffect } from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';

import pt from 'date-fns/locale/pt';

import { useField } from '@unform/core';

import 'react-datepicker/dist/react-datepicker.css';

import { Content } from './styles';

interface Props extends Omit<ReactDatePickerProps, 'onChange'> {
  name: string;
}

const DatePicker: React.FC<Props> = ({ name, ...rest }) => {
  const datepickerRef = useRef(null);
  const { fieldName, registerField, defaultValue } = useField(name);

  const [date, setDate] = useState(defaultValue);

  useEffect(() => {
    setDate(defaultValue);
    registerField({
      name: fieldName,
      ref: datepickerRef.current,
      path: 'props.selected',

      clearValue: (ref: any) => {
        ref.clear();
      },
    });
  }, [fieldName, registerField, defaultValue]);

  return (
    <Content>
      <ReactDatePicker
        ref={datepickerRef}
        autoComplete="off"
        locale={pt}
        selected={date}
        dateFormat="dd/MM/yyyy"
        onChange={setDate}
        id="datepicker"
        {...rest}
      />
    </Content>
  );
};

export default DatePicker;
