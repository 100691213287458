import React, { useRef, useEffect, SelectHTMLAttributes } from 'react';

import { useField } from '@unform/core';

import { Content } from './styles';

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  name: string;
  account?: number;
}

const SelectCreateUser: React.FC<SelectProps> = ({
  name,
  account,
  ...rest
}) => {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: 'value',
    });
  }, [fieldName, registerField, defaultValue]);

  return (
    <Content>
      <select ref={selectRef} placeholder="Selecionar" {...rest}>
        {account && account === 1 && <option value="1">Diretor</option>}
        <option value="2">Gestor</option>
        <option value="3">Colaborador</option>
      </select>
    </Content>
  );
};

export default SelectCreateUser;
