import styled from 'styled-components';
import { Form as Unform } from '@unform/web';

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.h1`
  letter-spacing: 1px;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  border-bottom: 1px solid #ddd;
`;

export const Avatar = styled.div`
  margin-bottom: 1.125rem;
  position: relative;

  img {
    height: 130px !important;
    width: 130px !important;
    border-radius: 50%;
    margin: auto;
  }

  label {
    width: 2.5rem;
    height: 2.5rem;
    position: absolute;
    bottom: 2px;
    right: 2px;
    background: #ff9800;
    border-radius: 50%;
    color: #fff;
    text-align: center;
    font-weight: bold;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.4);
    margin: auto;
    font-size: 1.875rem;
    cursor: pointer;

    svg {
      width: 1.25rem !important;
      height: 1.25rem !important;
      margin-bottom: 8px;
    }
  }

  svg {
    width: 130px;
    height: 130px;
  }

  input[type='file'] {
    display: none;
  }
`;

export const Form = styled(Unform)`
  margin: auto;
  width: 90% !important;
  display: flex;
  padding: 1rem;
  flex-direction: column;
`;
