import React, {
  useRef,
  useEffect,
  SelectHTMLAttributes,
  useState,
} from 'react';

import { useField } from '@unform/core';

import { Content } from './styles';
import { usePDCA } from '../../hooks/pdca';

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  name: string;
  option?: SelectRequest[];
  filter?: boolean;
  selectTask?: boolean;
}

interface SelectRequest {
  value: string;
  label: string;
}

const SelectPersonal: React.FC<SelectProps> = ({ name, option, ...rest }) => {
  const selectRef = useRef(null);
  const [options, setOptions] = useState<SelectRequest[]>([]);
  const { fieldName, defaultValue, registerField } = useField(name);
  const [nameSelect, setNameSelect] = useState('');
  const { pdca } = usePDCA();

  useEffect(() => {
    if (defaultValue && defaultValue.id) {
      setNameSelect(defaultValue.id);
    }

    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: 'value',
    });
  }, [fieldName, registerField, defaultValue]);

  useEffect(() => {
    pdca.map(async (list) => {
      setOptions((state) => [...state, { label: list.name, value: list.id }]);
    });
  }, [option, pdca]);

  return (
    <Content>
      <select
        defaultValue={nameSelect}
        value={nameSelect}
        onChange={(e) => {
          setNameSelect(e.target.value);
        }}
        ref={selectRef}
        placeholder="Selecionar"
        {...rest}
      >
        {options &&
          options.map((status) => (
            <option key={status.value} value={status.value}>
              {status.label}
            </option>
          ))}
      </select>
    </Content>
  );
};

export default SelectPersonal;
