import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { FcOldTimeCamera } from 'react-icons/fc';
import { FiEdit2 } from 'react-icons/fi';
import { Archive } from './styles';

interface Props {
  onFileUploaded: (file: File) => void;
  urlImage?: string;
}

const Dropzone: React.FC<Props> = ({ onFileUploaded, urlImage }) => {
  const [selectedFileUrl, setSelectedFileUrl] = useState('');

  useEffect(() => {
    if (urlImage) setSelectedFileUrl(urlImage);
  }, [urlImage]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      const fileUrl = URL.createObjectURL(file);

      setSelectedFileUrl(fileUrl);

      onFileUploaded(file);
    },
    [onFileUploaded],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*',
  });

  return (
    <>
      <Archive {...getRootProps()}>
        <input {...getInputProps()} accept="image/*" />

        {selectedFileUrl ? (
          <img src={selectedFileUrl} alt="Foto de perfil" />
        ) : (
          <FcOldTimeCamera />
        )}
        <label htmlFor="avatar">
          {' '}
          <FiEdit2 />{' '}
        </label>
      </Archive>
    </>
  );
};
export default Dropzone;
