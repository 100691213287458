import React, { createContext, useCallback, useContext } from 'react';
import api from '../services/api';

interface UserCreateRequest {
  name: string;
  email: string;
  company_id: string;
  account: number;
  phone: string;
}

interface UserEditRequest {
  id: string;
  avatar?: string;
  name: string;
  email: string;
  phone: string;
  password?: string;
  status_user: boolean;
}

interface UserContextState {
  createUser(userCreate: UserCreateRequest): Promise<boolean>;
  updateAvatar(dataAvatar: FormData): Promise<boolean>;
  editUser(userEdit: UserEditRequest): Promise<boolean>;
}

const UserContext = createContext<UserContextState>({} as UserContextState);

export const UserProvider: React.FC = ({ children }) => {
  const createUser = useCallback(
    async (dataCreateUser: UserCreateRequest): Promise<boolean> => {
      try {
        await api.post('/users', dataCreateUser);

        return true;
      } catch (error) {
        return false;
      }
    },
    [],
  );

  const updateAvatar = useCallback(
    async (dataAvatar: FormData): Promise<boolean> => {
      try {
        const result = await api.patch('/users/avatar', dataAvatar);

        if (result) {
          return true;
        }
        return false;
      } catch (error) {
        console.log(`Erro ao fazer upload! ${error}`);
        return false;
      }
    },
    [],
  );

  const editUser = useCallback(async (dataEditUser): Promise<boolean> => {
    try {
      const data = await api.patch('/users/update', dataEditUser);
      if (data) return true;
      return false;
    } catch (error) {
      return false;
    }
  }, []);

  return (
    <UserContext.Provider
      value={{
        createUser,
        updateAvatar,
        editUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export function useUser(): UserContextState {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error('useUser must be used within an UserProvider');
  }

  return context;
}
