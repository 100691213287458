import styled from 'styled-components';

export const Content = styled.div`
  input {
    flex: 1;
    background: #fff;
    border: 1px solid #fff !important;
    border-radius: 10px;
    color: #232129;
    padding: 0.5rem;
    margin-bottom: 5px;
    border-color: #ccc;
    font-size: 1rem;

    &::placeholder {
      color: #666360;
    }
  }
`;
