import styled from 'styled-components';
import { lighten } from 'polished';
import Modal from 'react-bootstrap/Modal';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

export const ContentPDCA = styled.div`
  height: 100%;
  width: 96%;
  align-self: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 1.875rem;
  margin-top: 0.875rem;
  margin-left: auto;
  margin-right: auto;
`;

export const MenuButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-content: right;
  margin-bottom: 10px;

  > a.linkVoltar {
    background: transparent;
    margin-right: 10px;
    text-decoration: none;
    padding: 5px 10px;
    align-self: center;
    border: 0;
    color: #5b437a;
    display: flex;
    transition: color 0.2s;

    &:hover {
      color: ${lighten(0.2, '#5b437a')};
    }

    svg {
      margin-right: 4px;
    }
  }
`;

export const ModalStyled = styled(Modal)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  max-width: 98vw;

  div {
    border-radius: 10px;
    background: #f3f3f3;
    justify-content: center;
    align-items: center;

    span.label {
      padding: 10px;
      color: #22215b;
    }
  }

  h1 {
    color: #957290;
    font-weight: 500;
    border: 0;
    margin-top: 4px;

    svg {
      position: absolute;
      right: 0.625rem;
      top: 0.625rem;
      color: #ff9000;
      cursor: pointer;
    }
  }

  Form {
    max-width: 320px;
    width: 100%;
    display: flex;
    flex-direction: column;

    @media (max-width: 800px) {
      max-width: 200px;
    }
  }

  .btnCompartilhar {
    position: absolute;
    background: #96adff;
    color: #ffffff;
    border-radius: 0.625rem;
    border: none;
    right: 76%;
    top: 0.625rem;
    padding: 0.5rem;

    @media (max-width: 800px) {
      right: 70%;
    }

    @media (max-width: 500px) {
      display: none;
    }
  }

  .btnVoltar {
    position: absolute;
    background: transparent;
    color: #ff9800;
    border-radius: 0.625rem;
    border: none;
    right: 88%;
    top: 0.625rem;
    padding: 0.625rem;
  }
`;

export const Fieldset = styled.fieldset`
  margin-top: 2rem;
  min-inline-size: auto;
  border: 0;
`;

export const Field = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const FieldGroup = styled.div`
  flex: 1;
  display: flex;

  @media (max-width: 950px) {
    flex-direction: column;
  }
`;

export const ListCustom = styled(List)`
  margin-right: 2rem !important;
  margin-bottom: 0;

  @media (max-width: 950px) {
    margin: 2rem !important;
  }
`;

export const ListItemCustom = styled(ListItem)`
  background-color: #fff !important;
  font-size: 1.1rem !important;
  border-radius: 0.35rem !important;
  font-weight: bold;

  svg {
    width: 30px;
    height: 30px;
    margin-right: 5px;
  }
`;
