import React, { useState, useCallback, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { FiArrowLeft, FiLock } from 'react-icons/fi';
import { FormHandles } from '@unform/core';

import { Form } from '@unform/web';
import * as Yup from 'yup';

import { toast } from 'react-toastify';
import Lottie from 'react-lottie';

import getValidationErrors from '../../utils/getValidationErrors';
import passwordLottie from '../../assets/password.json';

import {
  Container,
  Content,
  AnimationContainer,
  Background,
  DivArrow,
  Text,
  Image,
  Title,
} from './styles';

import logoImg from '../../assets/logo.svg';

import Input from '../../components/Input';
import Button from '../../components/Button';
import { useAuth } from '../../hooks/auth';

interface SignInFormData {
  password: string;
  confirmpassword?: string;
}

interface Request {
  email: string;
  password?: string;
}

const SignInPassword: React.FC = () => {
  const { info, updatePassword, signIn } = useAuth();
  const formRef = useRef<FormHandles>(null);

  const [statePassword, setStatePassword] = useState(info?.password);
  const history = useHistory();

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          password: Yup.string().min(6, 'No mínimo 6 dígitos'),
          confirmpassword: Yup.string().min(6, 'No mínimo 6 dígitos'),
        });

        await schema.validate(data, { abortEarly: false });

        if (data.confirmpassword) {
          if (data.password === data.confirmpassword) {
            await updatePassword(data.password);

            setStatePassword(true);

            return;
          }

          toast.error('Sua senha está divergente do campo Confirme sua Senha.');
          return;
        }

        const logon: Request = {
          email: String(info?.email),
          password: String(data.password),
        };

        const userData = await signIn(logon);

        userData ? history.push('/planoacao') : history.push('/dashboard');
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          formRef.current?.setErrors(errors);

          return;
        }

        toast.error('Verifique a senha.');
      }
    },
    [history, info, signIn, updatePassword],
  );

  return (
    <Container>
      <Background>
        <Lottie
          options={{
            animationData: passwordLottie,
            autoplay: true,
            loop: true,
          }}
          width="100%"
        />
      </Background>
      <DivArrow>
        <Link to="/">
          <Text>
            <FiArrowLeft size={28} />
            Voltar
          </Text>
        </Link>
      </DivArrow>

      {info?.email ? (
        <Content>
          <AnimationContainer>
            <Image src={logoImg} alt="SimplesPlan" />
            {statePassword ? (
              <Form ref={formRef} onSubmit={handleSubmit}>
                <Title>Insira sua senha</Title>
                <Input
                  name="password"
                  icon={FiLock}
                  type="password"
                  placeholder="Senha"
                  required
                />
                <Button type="submit">Acessar</Button>
              </Form>
            ) : (
              <Form ref={formRef} onSubmit={handleSubmit}>
                <Title>Cadastrar senha</Title>

                <Input
                  name="password"
                  icon={FiLock}
                  type="password"
                  placeholder="Senha"
                  required
                />
                <Input
                  name="confirmpassword"
                  icon={FiLock}
                  type="password"
                  placeholder="Confirme sua Senha"
                  required
                />
                <Button type="submit">Cadastrar Senha</Button>
              </Form>
            )}
          </AnimationContainer>
        </Content>
      ) : (
        history.push('/')
      )}
    </Container>
  );
};

export default SignInPassword;
