/* eslint-disable no-nested-ternary */
import React, { useState, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import Modal from 'react-bootstrap/Modal';
import { Grid, Tooltip } from '@material-ui/core';

import { FiEdit3, FiEdit, FiXCircle } from 'react-icons/all';

import { toast } from 'react-toastify';
import { usePDCA } from '../../../hooks/pdca';
import { useAuth } from '../../../hooks/auth';
import Input from '../../../components/Input';
import TextArea from '../../../components/TextArea';
import Button from '../../../components/Button';

import {
  ContentPDCA,
  Title,
  DivConfig,
  CardActionsCustom,
  CardContentCustom,
  CardCustom,
  TitleCard,
  ButtonAcessar,
  ButtonPlanAction,
  Container,
  Group,
  ModalStyled,
} from './styles';
import Header from '../../Header';

interface RequestEditPdca {
  id: string;
  name: string;
  description: string;
}

const Info: React.FC = () => {
  const [modalClosePdca, setModalClosePdca] = useState(false);
  const [dataPdca, setDataPdca] = useState<RequestEditPdca>();
  const { pdca, pdcaTask, editPDCA, listPDCA } = usePDCA();
  const formRef = useRef<FormHandles>(null);
  const { user } = useAuth();

  const history = useHistory();

  const handleOpenEditPdca = useCallback(async (data) => {
    setDataPdca(data);
    setModalClosePdca(true);
  }, []);

  const handleEditPDCA = useCallback(
    async (data) => {
      try {
        if (dataPdca) {
          await editPDCA({
            id: dataPdca.id,
            name: data.name,
            description: data.description,
          });
        }

        setModalClosePdca(false);

        toast.success('PDCA alterada com sucesso.');
        listPDCA();
      } catch (error) {
        toast.error('Erro ao alterar a PDCA.');
        setModalClosePdca(false);
      }
    },
    [editPDCA, dataPdca, listPDCA],
  );

  async function handleEnterPDCA(id: string): Promise<void> {
    history.push('acessarpdca', id);
  }

  function handleOpenPlan(): void {
    history.push('planoacao');
  }

  return (
    <Container>
      <Group>
        <Header />
        <ContentPDCA>
          <Title>
            <h1>PDCAs</h1>
            <Tooltip title="Ver plano de ação">
              <ButtonPlanAction type="button" onClick={handleOpenPlan}>
                plano de ação
              </ButtonPlanAction>
            </Tooltip>
          </Title>

          <DivConfig>
            <Grid
              container
              direction="row"
              justify="space-evenly"
              alignItems="flex-end"
            >
              {pdca &&
                pdca.map((resp) => (
                  <Grid item xs={4} key={resp.id}>
                    <CardCustom>
                      <CardContentCustom>
                        <TitleCard>{resp.name}</TitleCard>

                        <p>
                          <span>Empresa:</span> {resp.company_id.name}
                        </p>
                        <p>
                          <span>Gestor:</span> {resp.user_id.name}
                        </p>
                      </CardContentCustom>

                      <CardActionsCustom>
                        <Tooltip title="Ver pdca">
                          <ButtonAcessar
                            onClick={() => handleEnterPDCA(String(resp.id))}
                            type="button"
                            className="acessar"
                          >
                            Acessar
                          </ButtonAcessar>
                        </Tooltip>
                        {resp.user_id.id === user.id && (
                          <Tooltip title="Editar pdca">
                            <FiEdit
                              onClick={() => handleOpenEditPdca(resp)}
                              size={30}
                            />
                          </Tooltip>
                        )}
                      </CardActionsCustom>
                    </CardCustom>
                  </Grid>
                ))}
              {pdcaTask &&
                pdcaTask.map((resp) => (
                  <Grid item xs={4} key={resp.id}>
                    <CardCustom>
                      <CardContentCustom>
                        <TitleCard>{resp.namepdca}</TitleCard>

                        <p>
                          <span>Empresa:</span> {resp.namecompany}
                        </p>
                        <p>
                          <span>Gestor:</span> {resp.namegestor}
                        </p>
                      </CardContentCustom>
                      <CardActionsCustom>
                        <ButtonAcessar
                          onClick={() => handleEnterPDCA(String(resp.id))}
                          type="button"
                          className="acessar"
                        >
                          Acessar
                        </ButtonAcessar>
                      </CardActionsCustom>
                    </CardCustom>
                  </Grid>
                ))}
            </Grid>

            {!pdca
              ? !pdcaTask
                ? 'Ainda não existem pdcas vinculadas ao seu usuário!'
                : ''
              : ''}
          </DivConfig>

          {modalClosePdca ? (
            <ModalStyled
              show={modalClosePdca}
              onHide={() => setModalClosePdca(false)}
              labelledby="contained-modal-title-vcenter"
            >
              <Modal.Header>
                <h1>
                  Editar PDCA
                  <FiXCircle
                    size={30}
                    onClick={() => setModalClosePdca(false)}
                  />
                </h1>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <Form
                    ref={formRef}
                    initialData={dataPdca}
                    onSubmit={handleEditPDCA}
                  >
                    <span className="label">Nome da PDCA</span>
                    <Input name="name" icon={FiEdit3} required />

                    <span className="label">Descrição</span>
                    <TextArea
                      rows={5}
                      name="description"
                      icon={FiEdit3}
                      required
                    />

                    <Button type="submit">
                      <FiEdit size={30} /> Alterar
                    </Button>
                  </Form>
                </div>
              </Modal.Body>
            </ModalStyled>
          ) : null}
        </ContentPDCA>
      </Group>
    </Container>
  );
};

export default Info;
