import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  background-color: #fff !important;
  color: #666360 !important;
  border-radius: 10px !important;
  margin-top: 4px;

  select {
    width: 100%;
    border: 0;
    border-radius: 10px !important;
    padding: 0.5rem;
    cursor: pointer;
    color: #666360 !important;

    option {
      margin: 5px;
      height: 2rem;
      color: #666360 !important;
    }
  }
`;
