import styled from 'styled-components';

export const Content = styled.div`
  select {
    width: 100%;
    height: 2rem;
    border-radius: 10px;
    cursor: pointer;
    margin-top: 4px;
    color: #666360 !important;

    option {
      margin: 5px;
      width: 98%;
      height: 2rem;
      color: #666360 !important;
    }
  }
`;
