import styled from 'styled-components';

export const Container = styled.button`
  background: #957290 !important;
  height: 2.75rem;
  border-radius: 10px;
  border: 0;
  padding: 0 1rem;
  color: #ffffff !important;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 5px;
  transition: 0.2s;

  &:hover {
    background: #fff !important;
    color: #957290 !important;
    border: 2px solid #957290;
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;
