import React, { createContext, useCallback, useContext, useState } from 'react';

import api from '../services/api';

interface JustificationRequestCreate {
  pdca_id: string;
  task_id: string;
  user_id_receive: string;
  user_id_send: string;
  user_send: string;
  message: string;
}

interface JustificationRequest {
  id: string;
  user_send: string;
  message: string;
  user_id_send: {
    id: string;
  };
  created_at: Date;
}

interface JustificationContextData {
  createJustification(
    justification: JustificationRequestCreate,
  ): Promise<boolean>;
  searchListJustification(task_id: string): Promise<void>;
  viewStatusJustification(justification_id: string): Promise<void>;
  listJustification: JustificationRequest[];
  deleteJustification(
    justification_id: string,
    task_id: string,
  ): Promise<boolean>;
}

const JustificationContext = createContext<JustificationContextData>(
  {} as JustificationContextData,
);

const JustificationProvider: React.FC = ({ children }) => {
  const [listJustification, setListJustification] = useState<
    JustificationRequest[]
  >([]);
  // const { listTask } = useTask();

  const searchListJustification = useCallback(
    async (task_id: string): Promise<void> => {
      const resultListJustification = await api.get(`justification/${task_id}`);
      setListJustification(resultListJustification.data);
    },
    [],
  );

  const viewStatusJustification = useCallback(
    async (justification_id: string): Promise<void> => {
      await api.get(`justification/view/${justification_id}`);
    },
    [],
  );

  const createJustification = useCallback(
    async ({
      pdca_id,
      task_id,
      user_id_receive,
      user_id_send,
      user_send,
      message,
    }: JustificationRequestCreate): Promise<boolean> => {
      try {
        const justification: JustificationRequestCreate = {
          pdca_id,
          task_id,
          user_id_receive,
          user_id_send,
          user_send,
          message,
        };
        await api.post('justification', justification);
        searchListJustification(task_id);
        return true;
      } catch (error) {
        return false;
      }
    },
    [searchListJustification],
  );

  const deleteJustification = useCallback(
    async (justification_id: string, task_id: string): Promise<boolean> => {
      try {
        await api.delete(`justification/${justification_id}`);
        searchListJustification(task_id);
        return true;
      } catch (error) {
        return false;
      }
    },
    [searchListJustification],
  );

  return (
    <JustificationContext.Provider
      value={{
        createJustification,
        searchListJustification,
        viewStatusJustification,
        listJustification,
        deleteJustification,
      }}
    >
      {children}
    </JustificationContext.Provider>
  );
};

function useJustification(): JustificationContextData {
  const context = useContext(JustificationContext);

  if (!context) {
    throw new Error('useTask must be used within a TaskProvider');
  }

  return context;
}

export { JustificationProvider, useJustification };
