import React, {
  createContext,
  useCallback,
  useContext,
  useState,
  useEffect,
} from 'react';

import { zonedTimeToUtc } from 'date-fns-tz';
import api from '../services/api';
import { useAuth } from './auth';

interface TaskRequest {
  id: string;
  name: string;
  user_id: {
    id: string;
    name: string;
    avatar: string;
    email: string;
  };
  pdca_id: {
    id: string;
    name: string;
    description: string;
    user_id: {
      id: string;
    };
  };
  reason: string;
  action: string;
  date_start: Date;
  date_preview: Date;
  date_end: Date;
  status_task:
    | 'Pendente'
    | 'Andamento'
    | 'Concluído'
    | 'Cancelado'
    | 'Feedback'
    | undefined;
  justification_id: {
    user_send: string;
    view: boolean;
    message: string;
    id: string;
  };
}

interface TaskRequestCreateOrEdit {
  id?: string;
  name: string;
  user_id: string;
  pdca_id?: string;
  action: string;
  reason: string;
  date_start?: Date;
  date_preview: Date | string;
  date_end?: Date;
  status_task?:
    | 'Pendente'
    | 'Andamento'
    | 'Concluído'
    | 'Cancelado'
    | 'Feedback'
    | undefined;
  justification?: string;
}

interface CreatorPDCARequest {
  id: string;
  name: string;
  user_id: {
    id: string;
  };
}

interface ListUsersRequest {
  id: string;
  name: string;
  email: string;
  phone: string;
  account: number;
  status_user: boolean;
  avatar: string;
  created_at: Date;
}

interface FilterRequest {
  date_preview?: Date;
  pendente?: string;
  andamento?: string;
  concluido?: string;
  cancelado?: string;
  feedback?: string;
  user_id?: string;
  task_name?: string;
}

interface TaskContextData {
  createTask(task: TaskRequestCreateOrEdit): Promise<boolean>;
  updateTask(task: TaskRequestCreateOrEdit, filter: boolean): Promise<boolean>;
  shareTask(task: TaskRequestCreateOrEdit): Promise<boolean>;
  tasks: TaskRequest[];
  listTask(): void;
  planAction: TaskRequest[];
  listPlanAction(): Promise<void>;
  filterTask(filterRequest: FilterRequest): void;
  setPdcaID(pdca: string): void;
  listUsers: ListUsersRequest[];
  setTasks(task: TaskRequest[]): void;
  creatorPdca: CreatorPDCARequest | undefined;
  updateStatusTask(task_id: string, status_task: string): Promise<boolean>;
  deleteTask(task_id: string): Promise<boolean>;
  listUsersCompany(company_id: string): Promise<void>;
}

const TaskContext = createContext<TaskContextData>({} as TaskContextData);

const TaskProvider: React.FC = ({ children }) => {
  const [tasks, setTasks] = useState<TaskRequest[]>([]);
  const [pdcaID, setPdcaID] = useState('');
  const [creatorPdca, setCreatorPdca] = useState<CreatorPDCARequest>();
  const [listUsers, setListUsers] = useState([]);
  const { user } = useAuth();
  const [tasksData, setTasksData] = useState<TaskRequest[]>([]);
  const [filterData, setFilterData] = useState<FilterRequest>();

  const listUsersCompany = useCallback(
    async (company_id: string): Promise<void> => {
      const users = await api.get(`users/${company_id}`);

      setListUsers(users.data.user);
    },
    [],
  );

  const listTask = useCallback(async () => {
    if (pdcaID) {
      const pdca = await api.get(`pdca/creatorpdca/${pdcaID}`);

      setCreatorPdca(pdca.data);

      if (pdca?.data?.user_id?.id === user?.id || user?.account === 1) {
        const task = await api.get(`task/${pdcaID}`);

        if (task.data.length > 0) {
          setTasks(task.data);
        }
      } else {
        const myTask = await api.get(`task/${pdcaID}/user/${user?.id}`);
        setTasks(myTask.data);
      }

      listUsersCompany(user?.company_id?.id);
    }
  }, [pdcaID, user, listUsersCompany]);

  const listPlanAction = useCallback(async (): Promise<void> => {
    try {
      const planData = await api.get(`task/actionplan/${user.id}`);

      if (planData.data.length > 0) {
        setTasksData(planData.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, [user]);

  const filterTask = useCallback(
    async (filterRequest: FilterRequest) => {
      if (pdcaID) {
        setFilterData(filterRequest);
        const taskfilter = await api.post(
          `task/filter/${pdcaID}`,
          filterRequest,
        );
        setTasks(taskfilter.data);
      }
    },
    [pdcaID],
  );

  const createTask = useCallback(
    async ({
      name,
      user_id,
      action,
      reason,
      date_preview,
    }: TaskRequestCreateOrEdit): Promise<boolean> => {
      try {
        const task: TaskRequestCreateOrEdit = {
          name,
          user_id,
          pdca_id: pdcaID,
          action,
          reason,
          date_start: zonedTimeToUtc(new Date(), 'America/Sao_Paulo'),
          date_preview,
          status_task: 'Pendente',
        };

        await api.post('task', task);
        listTask();
        return true;
      } catch (error) {
        return false;
      }
    },
    [pdcaID, listTask],
  );

  const shareTask = useCallback(
    async ({
      name,
      user_id,
      action,
      reason,
      date_preview,
      pdca_id,
    }: TaskRequestCreateOrEdit): Promise<boolean> => {
      try {
        const task: TaskRequestCreateOrEdit = {
          name,
          user_id,
          pdca_id,
          action,
          reason,
          date_start: zonedTimeToUtc(new Date(), 'America/Sao_Paulo'),
          date_preview,
          status_task: 'Pendente',
        };

        await api.post('task', task);
        listTask();
        return true;
      } catch (error) {
        return false;
      }
    },
    [listTask],
  );

  const updateTask = useCallback(
    async (
      {
        id,
        name,
        user_id,
        action,
        reason,
        date_start,
        date_preview,
      }: TaskRequestCreateOrEdit,
      filter: boolean,
    ): Promise<boolean> => {
      try {
        const task: TaskRequestCreateOrEdit = {
          id,
          name,
          user_id,
          action,
          reason,
          date_start,
          date_preview,
        };

        await api.patch('task/update', task);
        if (filter === true && filterData) {
          filterTask(filterData);
        } else if (filter === false) {
          listTask();
        }
        return true;
      } catch (error) {
        return false;
      }
    },
    [filterData, filterTask, listTask],
  );

  const deleteTask = useCallback(
    async (task_id: string): Promise<boolean> => {
      try {
        await api.delete(`task/${task_id}`);
        await listTask();
        return true;
      } catch (error) {
        console.log(error);
        return false;
      }
    },
    [listTask],
  );

  const updateStatusTask = useCallback(
    async (task_id: string, status_task: string): Promise<boolean> => {
      try {
        await api.patch('/task', { task_id, status_task });
        return true;
      } catch (error) {
        console.log(error);
        return false;
      }
    },
    [],
  );

  useEffect(() => {
    listTask();
  }, [listTask]);

  return (
    <TaskContext.Provider
      value={{
        createTask,
        shareTask,
        updateTask,
        setPdcaID,
        planAction: tasksData,
        listPlanAction,
        filterTask,
        tasks,
        listTask,
        listUsers,
        listUsersCompany,
        setTasks,
        updateStatusTask,
        creatorPdca,
        deleteTask,
      }}
    >
      {children}
    </TaskContext.Provider>
  );
};

function useTask(): TaskContextData {
  const context = useContext(TaskContext);

  if (!context) {
    throw new Error('useTask must be used within a TaskProvider');
  }

  return context;
}

export { TaskProvider, useTask };
