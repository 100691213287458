/* eslint-disable no-nested-ternary */
import React, { useState, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import Modal from 'react-bootstrap/Modal';
import {
  FiArrowLeft,
  IoIosArrowDropdownCircle,
  IoIosArrowDropupCircle,
  FiFilter,
  AiOutlineClear,
  BiSearchAlt,
  FiXCircle,
} from 'react-icons/all';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Tooltip } from '@material-ui/core';

import Checkbox from '../../../components/CheckboxStatus';
import Graphic from '../../../components/Graphic';
import EnhancedTable from '../../../components/DataGrid';

import {
  MenuButton,
  GroupCheck,
  ModalStyledFilter,
  BuscaTask,
  Content,
  ButtonShow,
  DivButton,
  ButtonBuscar,
} from './styles';

import { useAuth } from '../../../hooks/auth';
import { useTask } from '../../../hooks/task';
import Button from '../../../components/Button';
import Header from '../../Header';

interface FilterRequest {
  pendente: string;
  andamento: string;
  concluido: string;
  cancelado: string;
  feedback: string;
}

interface TaskRequest {
  id: string;
  name: string;
  user_id: {
    id: string;
    name: string;
    email: string;
  };
  pdca_id: {
    id: string;
    name: string;
    description: string;
    user_id: {
      id: string;
    };
  };
  reason: string;
  action: string;
  date_start: Date;
  date_preview: Date;
  date_end: Date;
  status_task:
    | 'Pendente'
    | 'Andamento'
    | 'Concluído'
    | 'Cancelado'
    | 'Feedback'
    | undefined;
  justification_id: {
    user_send: string;
    view: boolean;
    message: string;
    id: string;
  };
}

const PlanAction: React.FC = () => {
  const [cardTask, setCardTask] = useState(true);
  const [filter, setFilter] = useState(false);
  const [addFilterModal, setAddFilterModal] = useState(false);

  const formRefFilter = useRef<FormHandles>(null);
  const { listPlanAction, planAction } = useTask();
  const [filterData, setFilterData] = useState<TaskRequest[]>([]);

  const { user } = useAuth();

  async function handleCardTask(): Promise<void> {
    cardTask ? setCardTask(false) : setCardTask(true);
  }

  async function handleFilter(): Promise<void> {
    addFilterModal ? setAddFilterModal(false) : setAddFilterModal(true);
  }

  const handleSelectFilter = useCallback(
    async (data: FilterRequest): Promise<void> => {
      if (data.andamento !== '') {
        const filterAndamento = planAction.filter(
          (taskFilters) => taskFilters.status_task === 'Andamento',
        );
        filterAndamento.map(async (filtered) => {
          setFilterData((state) => [...state, filtered]);
        });
      }
      if (data.pendente !== '') {
        const filterPendente = planAction.filter(
          (taskFilters) => taskFilters.status_task === 'Pendente',
        );
        filterPendente.map(async (filtered) => {
          setFilterData((state) => [...state, filtered]);
        });
      }
      if (data.concluido !== '') {
        const filterConcluido = planAction.filter(
          (taskFilters) => taskFilters.status_task === 'Concluído',
        );
        filterConcluido.map(async (filtered) => {
          setFilterData((state) => [...state, filtered]);
        });
      }
      if (data.cancelado !== '') {
        const filterCancelado = planAction.filter(
          (taskFilters) => taskFilters.status_task === 'Cancelado',
        );
        filterCancelado.map(async (filtered) => {
          setFilterData((state) => [...state, filtered]);
        });
      }
      if (data.feedback !== '') {
        const filterCancelado = planAction.filter(
          (taskFilters) => taskFilters.status_task === 'Feedback',
        );
        filterCancelado.map(async (filtered) => {
          setFilterData((state) => [...state, filtered]);
        });
      }

      setFilter(true);
      setAddFilterModal(false);
    },
    [planAction],
  );

  const handleListTask = useCallback(async (): Promise<void> => {
    window.location.reload();
    listPlanAction();
    setFilter(false);
    toast.info('Filtro limpo com sucesso.');
  }, [listPlanAction]);

  return (
    <>
      <Header />
      <Content>
        <MenuButton>
          {user.account === 3 ? (
            <div />
          ) : (
            <Link to="/dashboard" className="linkVoltar">
              <FiArrowLeft />
              Voltar
            </Link>
          )}

          <DivButton>
            {filter === true ? (
              <Button onClick={handleListTask} type="button">
                <AiOutlineClear size={24} />
                Limpar Filtro
              </Button>
            ) : (
              <Button onClick={handleFilter} type="button">
                <FiFilter size={24} />
                Filtrar
              </Button>
            )}
          </DivButton>
        </MenuButton>

        {cardTask ? (
          <>
            <Graphic />
            <Tooltip title="Fechar gráfico">
              <ButtonShow type="button" onClick={handleCardTask}>
                <IoIosArrowDropupCircle />
              </ButtonShow>
            </Tooltip>
          </>
        ) : (
          <Tooltip title="Abrir gráfico">
            <ButtonShow type="button" onClick={handleCardTask}>
              <IoIosArrowDropdownCircle />
            </ButtonShow>
          </Tooltip>
        )}

        {planAction.length < 0 ? (
          <p>Nenhuma tarefa encontrada.</p>
        ) : filter === true ? (
          <EnhancedTable rows={filterData} />
        ) : (
          <EnhancedTable rows={planAction} />
        )}

        {addFilterModal && (
          <ModalStyledFilter
            show={addFilterModal}
            onHide={() => setAddFilterModal(false)}
          >
            <Modal.Header>
              <h1>
                Filtrar
                <FiXCircle size={30} onClick={() => setAddFilterModal(false)} />
              </h1>
            </Modal.Header>

            <Modal.Body>
              <BuscaTask>
                <Form ref={formRefFilter} onSubmit={handleSelectFilter}>
                  <p>Selecione o status das tarefas.</p>
                  <GroupCheck>
                    <Checkbox name="pendente" textname="Pendente" />
                    <Checkbox name="andamento" textname="Andamento" />
                    <Checkbox name="concluido" textname="Concluído" />
                    <Checkbox name="cancelado" textname="Cancelado" />
                    <Checkbox name="feedback" textname="Feedback" />
                  </GroupCheck>

                  <ButtonBuscar type="submit">
                    <BiSearchAlt />
                    Buscar
                  </ButtonBuscar>
                </Form>
              </BuscaTask>
            </Modal.Body>
          </ModalStyledFilter>
        )}
      </Content>
    </>
  );
};

export default PlanAction;
