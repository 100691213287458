import styled from 'styled-components';

export const Container = styled.div`
  > div {
    margin: 5px 10px;
    display: flex;
    align-items: center;
    text-align: center;

    span {
      margin-left: 5px;
      color: #555;
      font-size: 0.75rem;
    }
  }
`;
