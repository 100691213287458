import styled, { keyframes } from 'styled-components';

import { lighten } from 'polished';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: stretch;
`;

export const DivArrow = styled.div`
  margin: 4% 0 0 4%;
`;

export const Text = styled.h3`
  color: #957290;

  @media (max-width: 700px) {
    display: none;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 700px;
`;

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to{
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Image = styled.img`
  width: 400px;
  height: 140px;
  margin-bottom: 5%;

  @media (max-width: 700px) {
    width: 300px;
    height: 100px;
  }
`;

export const Title = styled.h1`
  margin-bottom: 5%;
  color: #957290;
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  animation: ${appearFromRight} 1s;

  form {
    margin: 5% 0;
    width: 300px;
    text-align: center;

    a {
      color: #957290;
      display: block;
      margin-top: 24px;
      text-decoration: none;
      transition: color 0.2s;

      &:hover {
        color: ${lighten(0.4, '#957290')};
      }
    }
  }

  > a {
    color: #ff9000;
    display: block;
    margin-top: 4%;
    text-decoration: none;
    transition: color 0.2s;
    display: flex;
    align-items: center;

    svg {
      margin-right: 16px;
    }

    &:hover {
      color: ${lighten(0.4, '#ff9000')};
    }
  }
`;

export const Background = styled.div`
  flex: 1;

  @media (max-width: 900px) {
    display: none;
  }
`;
