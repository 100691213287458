import styled from 'styled-components';

export const Container = styled.div`
  > div {
    margin: 3px 1px;

    span {
      margin-left: 4px !important;
      font-weight: normal;
      color: #555;
    }

    input {
      width: 20px !important;
    }
  }
`;
