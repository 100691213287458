import styled from 'styled-components';

export const Div = styled.div`
  justify-content: center;
  text-align: center;
  margin: auto;
  display: flex;
  width: 50%;
  height: 300px;
`;

export const Legend = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: justify;

  h3 {
    font-size: 2rem !important;
    color: #957290;
  }
`;

export const Title = styled.h3`
  font-size: 2rem !important;
  color: #957290;
`;

export const Items = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;

  svg {
    margin-right: 2px;
    width: 1.75rem;
    height: 1.75rem;
  }
`;

export const Text = styled.p`
  margin: auto;
  color: #000;
`;
