import React from 'react';

import { UserProvider } from './user';
import { AuthProvider } from './auth';
import { PDCAProvider } from './pdca';
import { TaskProvider } from './task';
import { JustificationProvider } from './justification';

const AppProvider: React.FC = ({ children }) => (
  <UserProvider>
    <AuthProvider>
      <PDCAProvider>
        <TaskProvider>
          <JustificationProvider>{children}</JustificationProvider>
        </TaskProvider>
      </PDCAProvider>
    </AuthProvider>
  </UserProvider>
);

export default AppProvider;
