import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Routes from './routes';
import GlobalStyle from './styles/global';
import 'bootstrap/dist/css/bootstrap.min.css';

import AppProvider from './hooks';

const App: React.FC = () => (
  <>
    <BrowserRouter>
      <AppProvider>
        <Routes />
      </AppProvider>
      <ToastContainer autoClose={2000} position="top-center" />
      <GlobalStyle />
    </BrowserRouter>
  </>
);

export default App;
