import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  width: 100%;
  background: #fff !important;
  border-radius: 10px;
  border: 2px solid #fff !important;
  padding: 0.5rem;
  color: #666360 !important;
  align-items: center;

  select {
    flex: 1;
    background: none;
    border: 0;
    cursor: pointer;
    color: #666360 !important;

    option {
      margin: 5px;
      color: #666360 !important;
      cursor: pointer;
    }
  }
`;
