/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useCallback, useRef, useEffect } from 'react';

import Brlocale from 'date-fns/locale/pt-BR';
import { zonedTimeToUtc } from 'date-fns-tz';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { format, parseISO, intervalToDuration } from 'date-fns';

import Modal from 'react-bootstrap/Modal';
import ButtonBootstrap from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';

import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  TablePagination,
  TableContainer,
  IconButton,
  TableBody,
  TableHead,
  Collapse,
  TableRow,
  Tooltip,
  Grid,
  Box,
} from '@material-ui/core';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

import {
  FiPlus,
  FiFilter,
  FiArrowRight,
  FiXCircle,
  FiSave,
  AiOutlineClear,
  BiSearchAlt,
  SiWechat,
  FcCollapse,
  FcExpand,
  FiEdit,
  FiTrash2,
  AiFillQuestionCircle,
  BiShareAlt,
  SiGooglehangoutschat,
} from 'react-icons/all';

import Input from '../Input';
import Textarea from '../TextArea';
import DatePicker from '../DatePicker';
import SelectPersonal from '../SelectPersonal';
import Button from '../Button';
import Select from '../Select';

import getValidationErrors from '../../utils/getValidationErrors';

import { useAuth } from '../../hooks/auth';
import { useTask } from '../../hooks/task';
import { useJustification } from '../../hooks/justification';
import { usePDCA } from '../../hooks/pdca';

import {
  DivDetails,
  TypographyCustom,
  TableCustom,
  TableCellCustom,
  TableCellHeaderCustom,
  ModalStyled,
  Tdstatus,
  Col1,
  Col2,
  Group,
  TableSortLabelCustom,
  TableFooterCustom,
  CreateEditGroup,
  GroupOne,
  GroupTwo,
  ModalStyledFilter,
  Field,
  FieldGroup,
  Fieldset,
  GroupCheck,
  GroupForm,
  BuscaTask,
  ToolbarCustom,
  Title,
  ButtonShare,
  Items,
  Label,
  TextEmail,
  Rows,
  TextRows,
  ButtonDelete,
  TitleGroup,
  ButtonDeleteModal,
  ButtonSearch,
  ButtonEdit,
  ButtonMenu,
  RowCustom,
  DivToolbar,
  TextFieldCustom,
  AvatarStyled,
  TooltipStyled,
  DivTooltip,
  Text,
  AvatarStyled2,
} from './styles';
import Checkbox from '../CheckboxStatus';
import InputSimple from '../InputSimple';

interface Data {
  id: string;
  name: string;
  user_id: {
    id: string;
    name: string;
    avatar: string;
    email: string;
  };
  pdca_id: {
    id: string;
    name: string;
    user_id: {
      id: string;
    };
  };
  action: string;
  reason: string;
  date_start: Date;
  date_preview: Date;
  date_end: Date;
  status_task:
    | 'Pendente'
    | 'Andamento'
    | 'Concluído'
    | 'Cancelado'
    | 'Feedback'
    | undefined;
  justification_id: {
    message: string;
    user_send: string;
    view: boolean;
    id: string;
  };
}

interface FilterTask {
  date_preview?: Date;
  andamento?: string;
  concluido?: string;
  pendente?: string;
  cancelado?: string;
  feedback?: string;
  task_name?: string;
  user_id?: string;
}

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => void;
  order: Order;
  orderBy: string;
  // eslint-disable-next-line react/no-unused-prop-types
  rowCount: number;
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

interface Props {
  pdca_id: string;
}

interface SelectRequest {
  value: string;
  label: string;
}

type Order = 'asc' | 'desc';

const headCells: HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Tarefa',
  },
  {
    id: 'user_id',
    numeric: false,
    disablePadding: true,
    label: 'Responsável',
  },
  {
    id: 'date_preview',
    numeric: true,
    disablePadding: false,
    label: 'Fim previsto',
  },
  {
    id: 'date_end',
    numeric: true,
    disablePadding: false,
    label: 'Término oficial',
  },
  {
    id: 'status_task',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'justification_id',
    numeric: false,
    disablePadding: false,
    label: 'Follow-up',
  },
];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T): number {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: {
    [key in Key]: string | number | boolean | object | undefined | null;
  },
  b: {
    [key in Key]: string | number | boolean | object | undefined | null;
  },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number): any {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props: EnhancedTableProps): any {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof Data) => (
    event: React.MouseEvent<unknown>,
  ) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCellHeaderCustom />
        {headCells.map((headCell) => (
          <TableCellHeaderCustom
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <Tooltip title="Ordenar">
              <TableSortLabelCustom
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabelCustom>
            </Tooltip>
          </TableCellHeaderCustom>
        ))}
      </TableRow>
    </TableHead>
  );
}

const EnhancedTable: React.FC<Props> = ({ pdca_id }) => {
  const [addJustificationModal, setAddJustificationModal] = useState(false);
  const [deleteTaskModal, setDeleteTaskModal] = useState(false);
  const [addStatusModal, setAddStatusModal] = useState(false);
  const [addFilterModal, setAddFilterModal] = useState(false);
  const [addModalEdit, setAddModalEdit] = useState(false);
  const [addTaskModal, setAddTaskModal] = useState(false);
  const [addShareModal, setShareModal] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [filter, setFilter] = useState(false);
  const [open, setOpen] = useState(false);

  const [taskDetails, setTaskDetails] = useState<Data>();
  const [openTask, setOpenTask] = useState<Data>();
  const [editTaskFilter, setEditTaskFilter] = useState<Data>();

  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [page, setPage] = useState(0);

  const [orderBy, setOrderBy] = useState<keyof Data>('reason');
  const [order, setOrder] = useState<Order>('asc');
  const [idUserCreateTask, setIdUserCreateTask] = useState('');
  const [idUserFilterTask, setIdUserFilterTask] = useState('');
  const [idOpenTooltip, setIdOpenTooltip] = useState('');
  const [openId, setOpenId] = useState('');
  const [idTask, setIdTask] = useState('');

  const [optionLoad, setOptionLoad] = useState<SelectRequest[]>([]);
  const [optionPdca, setOptionPdca] = useState<SelectRequest[]>([]);

  const formRefJustification = useRef<FormHandles>(null);
  const formRefFilter = useRef<FormHandles>(null);
  const formRef = useRef<FormHandles>(null);

  const { pdca } = usePDCA();
  const {
    setPdcaID,
    shareTask,
    updateTask,
    updateStatusTask,
    deleteTask,
    tasks,
    listUsers,
    creatorPdca,
    createTask,
    filterTask,
    listTask,
  } = useTask();
  const [taskList, setTaskList] = useState(tasks);
  const { user } = useAuth();

  const {
    searchListJustification,
    viewStatusJustification,
    listJustification,
    createJustification,
    deleteJustification,
  } = useJustification();

  const numberRows = taskList.length;

  useEffect(() => {
    if (optionLoad.length !== listUsers.length) {
      listUsers.map((users) => {
        return setOptionLoad((state) => [
          ...state,
          { value: users.id, label: users.name },
        ]);
      });
    }
    if (optionPdca.length !== pdca.length) {
      // eslint-disable-next-line no-shadow
      pdca.map((pdca) => {
        return setOptionPdca((state) => [
          ...state,
          { value: pdca.id, label: pdca.name },
        ]);
      });
    }
  }, [listUsers, pdca, optionLoad.length, optionPdca.length]);

  useEffect(() => {
    setPdcaID(String(pdca_id));
    setTaskList(tasks);
  }, [setPdcaID, pdca, tasks, pdca_id]);

  async function handleAddTaskModal(): Promise<void> {
    setAddTaskModal(true);
  }

  async function handleFilter(): Promise<void> {
    addFilterModal ? setAddFilterModal(false) : setAddFilterModal(true);
  }

  const handleListTask = useCallback(async (): Promise<void> => {
    setOptionLoad([]);
    listTask();
    formRefFilter.current?.clearField('task_name');
    formRefFilter.current?.clearField('date_preview');
    formRefFilter.current?.reset();
    setFilter(false);
    toast.info('Filtro limpo com sucesso.');
    setIdUserFilterTask('');
  }, [listTask]);

  const handleSelectFilter = useCallback(
    async (data: FilterTask): Promise<void> => {
      if (idUserFilterTask !== '') {
        filterTask({
          date_preview: data.date_preview,
          andamento: data.andamento,
          concluido: data.concluido,
          pendente: data.pendente,
          cancelado: data.cancelado,
          feedback: data.feedback,
          task_name: data.task_name,
          user_id: idUserFilterTask,
        });
      } else {
        filterTask({
          date_preview: data.date_preview,
          andamento: data.andamento,
          concluido: data.concluido,
          pendente: data.pendente,
          cancelado: data.cancelado,
          feedback: data.feedback,
          task_name: data.task_name,
          user_id: 'Todos',
        });
      }
      setIdUserFilterTask('');
      setFilter(true);
      setAddFilterModal(false);
    },
    [filterTask, idUserFilterTask],
  );

  const handleCreateTask = useCallback(
    async (data) => {
      try {
        const taskData = await createTask({
          name: data.name,
          action: data.action,
          date_preview: data.date_preview,
          reason: data.reason,
          user_id: idUserCreateTask,
        });
        if (taskData) {
          setAddTaskModal(false);
          setIdUserCreateTask('');
          formRef.current?.reset();
          toast.success('Tarefa criada com sucesso.');
        } else {
          toast.error('Erro ao criar tarefa.');
        }
      } catch (error) {
        console.log(error);
      }
    },
    [createTask, idUserCreateTask],
  );

  function handleOpenStatusModal(): void {
    addStatusModal ? setAddStatusModal(false) : setAddStatusModal(true);
  }

  const handleClick = (id: string): void => {
    const filterData = taskList.filter((row) => row.id === id);

    setTaskDetails(filterData[0]);

    setOpenId(id);

    setOpen(!open);
  };

  const handleChangePage = (event: unknown, newPage: number): void => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ): void => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleOpenJustificationModal = useCallback(
    async (task: Data): Promise<void> => {
      try {
        setAddJustificationModal(true);

        await searchListJustification(task.id);
        if (user.name !== task.justification_id.user_send) {
          await viewStatusJustification(task?.justification_id?.id);
        }
      } catch (error) {
        console.log(error);
      }
    },
    [searchListJustification, viewStatusJustification, user],
  );

  const handleAlterStatus = useCallback(
    async ({ status_task }) => {
      try {
        if (openTask && openTask.id) {
          updateStatusTask(openTask.id, status_task)
            .then(() => {
              const fato = taskList.findIndex(
                (task) => task.id === openTask.id,
              );
              taskList[fato].status_task = status_task;
              setTaskList(taskList);
              setAddStatusModal(false);
              toast.success('Alterado com sucesso!');
            })
            .catch((e) => {
              toast.error('Erro ao alterar o status.', e);
            });
        } else {
          console.log('no openTask');
        }
      } catch (error) {
        console.log(error);
      }
    },
    [openTask, updateStatusTask, taskList],
  );

  const handleCreateJustification = useCallback(
    async ({ message }) => {
      try {
        if (openTask) {
          const justificationData = await createJustification({
            message,
            pdca_id: openTask.pdca_id.id,
            task_id: openTask.id,
            user_id_receive: user.id,
            user_id_send: user.id,
            user_send: user.name,
          });

          if (justificationData) {
            toast.success('Follow-up criado com sucesso!');
            formRefJustification.current?.reset();
          } else {
            toast.error('Erro ao criar follow-up.');
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    [createJustification, user, openTask],
  );

  const handleDeleteJustification = useCallback(
    async (justification_id: string) => {
      try {
        if (openTask && openTask.id) {
          const justificationData = await deleteJustification(
            justification_id,
            openTask.id,
          );

          if (justificationData) {
            setAddJustificationModal(false);
            toast.info('Follow-up excluído com sucesso.');
          } else {
            setAddJustificationModal(false);
            toast.error('Erro ao excluir follow-up.');
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    [deleteJustification, openTask],
  );

  const handleEditTask = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Digite o nome da tarefa'),
          reason: Yup.string().required('Digite o motivo da tarefa'),
          action: Yup.string().required('Digite a ação da tarefa'),
          date_preview: Yup.date().required(
            'Digite a data prevista do fim da tarefa',
          ),
        });

        await schema.validate(data, { abortEarly: false });

        if (editTaskFilter) {
          updateTask(
            {
              id: editTaskFilter.id,
              name: data.name,
              user_id: data.user_id,
              reason: data.reason,
              action: data.action,
              date_start: data.date_start,
              date_preview: data.date_preview,
              status_task: data.status_task,
            },
            filter,
          )
            .then(() => {
              setAddModalEdit(false);
              toast.success('Tarefa atualizada com sucesso.');
              setOpen(false);
            })
            .catch((e) => {
              toast.error('Erro ao atualizar a tarefa.', e);
            });
        } else {
          toast.error('Ocorreu um erro!');
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          formRef.current?.setErrors(errors);
        }

        toast.error('Verifique se os campos estão preenchidos.');
      }
    },
    [editTaskFilter, updateTask, filter],
  );

  const handleShareTask = useCallback(
    async (data) => {
      setShareModal(false);

      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Digite o nome da tarefa'),
          reason: Yup.string().required('Digite o motivo da tarefa'),
          action: Yup.string().required('Digite a ação da tarefa'),
          date_preview: Yup.date().required(
            'Digite a data prevista do fim da tarefa',
          ),
        });

        await schema.validate(data, { abortEarly: false });

        setPdcaID(data.pdca_id);

        const taskData = await shareTask(data);

        if (taskData) {
          formRef.current?.reset();
          toast.success('Tarefa criada com sucesso.');
        } else {
          toast.error('Erro ao criar tarefa.');
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          formRef.current?.setErrors(errors);
        }

        toast.error('Verifique se os campos estão preenchidos.');
      }
    },
    [setPdcaID, shareTask],
  );

  const handleDataEditTask = useCallback(
    async (task_id: string): Promise<void> => {
      const result = taskList.filter((task) => task.id === task_id);
      result.map(async (res) => {
        setEditTaskFilter({
          id: res.id,
          name: res.name,
          user_id: res.user_id,
          reason: res.reason,
          action: res.action,
          date_preview: parseISO(String(res.date_preview)),
          date_end: parseISO(String(res.date_end)) || null,
          pdca_id: res.pdca_id,
          date_start: parseISO(String(res.date_start)),
          status_task: res.status_task,
          justification_id: res.justification_id || null,
        });

        setIdTask(task_id);
      });
    },
    [taskList],
  );

  const handleDayPreview = useCallback((date: Date): number => {
    const { days } = intervalToDuration({
      start: new Date(),
      end: new Date(date),
    });

    if (days) {
      return days;
    }
    return 0;
  }, []);

  const handleDeleteTask = useCallback(async () => {
    const taskData = await deleteTask(idTask);

    if (taskData) {
      setDeleteTaskModal(false);
      toast.info('Excluído tarefa com sucesso.');
    } else {
      setDeleteTaskModal(false);
      toast.error('Erro ao deletar a tarefa.');
    }
  }, [deleteTask, idTask]);

  const handleClose = (): void => {
    setOpenTooltip(false);
    setIdOpenTooltip('');
  };

  const handleOpen = (id: string): void => {
    setOpenTooltip(true);
    setIdOpenTooltip(id);
  };

  return (
    <>
      <TableContainer>
        <ToolbarCustom>
          <DivToolbar>
            {creatorPdca?.user_id.id === user.id || user.account === 1 ? (
              <>
                <ButtonMenu onClick={handleAddTaskModal} type="button">
                  <FiPlus />
                  Nova tarefa
                </ButtonMenu>

                {filter === true ? (
                  <ButtonMenu
                    onClick={handleListTask}
                    className="limparFiltro"
                    type="button"
                  >
                    <AiOutlineClear />
                    Limpar
                  </ButtonMenu>
                ) : (
                  <ButtonMenu onClick={handleFilter} type="button">
                    <FiFilter />
                    Filtrar
                  </ButtonMenu>
                )}
              </>
            ) : (
              <div />
            )}
          </DivToolbar>
        </ToolbarCustom>
        <Title>
          Pdca: <span> {creatorPdca?.name}</span>
        </Title>
        <TableCustom
          aria-labelledby="Pdca"
          size="medium"
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={taskList.length}
          />
          <TableBody>
            {stableSort(taskList, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row: Data) => {
                const userName = row.user_id.name.split(' ');
                const firstName = userName[0];
                const stringAvatar = firstName.substr(0, 1);

                return (
                  <React.Fragment key={row.id}>
                    <TableRow tabIndex={-1}>
                      <TableCellCustom>
                        <Tooltip title="Ver tarefa">
                          <IconButton
                            aria-label="expand row"
                            size="medium"
                            onClick={() => handleClick(String(row.id))}
                          >
                            {open ? (
                              row.id === openId ? (
                                <FcCollapse />
                              ) : (
                                <FcExpand />
                              )
                            ) : (
                              <FcExpand />
                            )}
                          </IconButton>
                        </Tooltip>
                      </TableCellCustom>

                      <TableCellCustom align="center">
                        {row.name}
                      </TableCellCustom>

                      <TableCellCustom align="center">
                        <TooltipStyled
                          open={row.id === idOpenTooltip ? openTooltip : false}
                          onClose={handleClose}
                          onOpen={() => handleOpen(row.id)}
                          // eslint-disable-next-line
                          title={(
                            <DivTooltip>
                              <Row>
                                {row.user_id.avatar ? (
                                  <AvatarStyled2
                                    src={row.user_id.avatar}
                                    alt="avatar"
                                  />
                                ) : (
                                  <AvatarStyled2>{stringAvatar}</AvatarStyled2>
                                )}

                                <Text>{row.user_id.name}</Text>
                              </Row>
                              <TextEmail>{row.user_id.email}</TextEmail>
                            </DivTooltip>
                            // eslint-disable-next-line
                          )}
                        >
                          <Row>
                            {row.user_id.avatar ? (
                              <AvatarStyled
                                src={row.user_id.avatar}
                                alt="avatar"
                              />
                            ) : (
                              <AvatarStyled>{stringAvatar}</AvatarStyled>
                            )}

                            {row.user_id.name}
                          </Row>
                        </TooltipStyled>
                      </TableCellCustom>

                      <TableCellCustom align="center">
                        {handleDayPreview(row.date_preview) >= 3 ||
                        row.status_task === 'Concluído' ||
                        row.status_task === 'Cancelado' ||
                        row.status_task === 'Feedback' ? (
                          format(new Date(row.date_preview), 'dd/MM/yyyy', {
                            locale: Brlocale,
                          })
                        ) : (
                          <p className="alertDate">
                            {format(new Date(row.date_preview), 'dd/MM/yyyy', {
                              locale: Brlocale,
                            })}
                          </p>
                        )}
                      </TableCellCustom>

                      <TableCellCustom align="center">
                        {row.date_end ? (
                          format(new Date(row.date_end), 'dd/MM/yyyy', {
                            locale: Brlocale,
                          })
                        ) : (
                          <AiFillQuestionCircle size={30} color="#957290" />
                        )}
                      </TableCellCustom>

                      <TableCellCustom>
                        <Tooltip title="Clique para alterar">
                          <Tdstatus
                            type={row.status_task}
                            onClick={() => {
                              setOpenTask(row);
                              handleOpenStatusModal();
                            }}
                          >
                            {row.status_task}
                          </Tdstatus>
                        </Tooltip>
                      </TableCellCustom>

                      <TableCellCustom align="center">
                        {row?.justification_id?.user_send !== user.name &&
                        row.justification_id &&
                        !row?.justification_id?.view ? (
                          <Tooltip title="Novo follow-up">
                            <button
                              className="notification"
                              onClick={() => {
                                setOpenTask(row);
                                handleOpenJustificationModal(row);
                              }}
                              type="button"
                            >
                              <SiGooglehangoutschat size={30} />
                            </button>
                          </Tooltip>
                        ) : (
                          <Tooltip title="Ver follow-up">
                            <button
                              className="notNotification"
                              onClick={() => {
                                setOpenTask(row);
                                handleOpenJustificationModal(row);
                              }}
                              type="button"
                            >
                              <SiWechat />
                            </button>
                          </Tooltip>
                        )}
                      </TableCellCustom>
                    </TableRow>
                    {openId === row.id ? (
                      <TableRow>
                        <TableCellCustom
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          colSpan={7}
                        >
                          <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box margin={1}>
                              <TypographyCustom>
                                <RowCustom>
                                  <h3>Detalhes</h3>
                                  <Row>
                                    {user.account !== 3 ? (
                                      <Tooltip title="Compartilhar">
                                        <ButtonShare
                                          type="button"
                                          onClick={() => {
                                            setShareModal(true);
                                            handleDataEditTask(row.id);
                                          }}
                                        >
                                          <BiShareAlt size={20} />
                                        </ButtonShare>
                                      </Tooltip>
                                    ) : (
                                      ''
                                    )}
                                    {creatorPdca?.user_id.id === user.id ? (
                                      <Tooltip title="Editar">
                                        <ButtonEdit
                                          type="button"
                                          onClick={() => {
                                            handleDataEditTask(row.id);
                                            setAddModalEdit(true);
                                          }}
                                        >
                                          <FiEdit size={20} />
                                        </ButtonEdit>
                                      </Tooltip>
                                    ) : null}
                                  </Row>
                                </RowCustom>

                                <hr />
                              </TypographyCustom>

                              <DivDetails>
                                <Grid container spacing={2}>
                                  <Grid item xs={5}>
                                    <Items>
                                      <strong>Tarefa:</strong>
                                      <span>{taskDetails?.name} </span>
                                    </Items>

                                    <Items>
                                      <strong>Status:</strong>
                                      <span>{taskDetails?.status_task} </span>
                                    </Items>

                                    <Items>
                                      <strong>Início:</strong>
                                      <span>
                                        {taskDetails?.date_start ? (
                                          format(
                                            new Date(taskDetails?.date_start),
                                            'dd/MM/yyyy',
                                            {
                                              locale: Brlocale,
                                            },
                                          )
                                        ) : (
                                          <AiFillQuestionCircle />
                                        )}
                                      </span>
                                    </Items>

                                    <Items>
                                      <strong>Termino previsto:</strong>
                                      <span>
                                        {taskDetails?.date_preview ? (
                                          format(
                                            new Date(taskDetails?.date_preview),
                                            'dd/MM/yyyy',
                                            {
                                              locale: Brlocale,
                                            },
                                          )
                                        ) : (
                                          <AiFillQuestionCircle />
                                        )}
                                      </span>
                                    </Items>
                                  </Grid>

                                  <Grid item xs={7}>
                                    <Items>
                                      <strong>Termino oficial:</strong>
                                      <span>
                                        {taskDetails?.date_end ? (
                                          format(
                                            new Date(taskDetails?.date_end),
                                            'dd/MM/yyyy',
                                            {
                                              locale: Brlocale,
                                            },
                                          )
                                        ) : (
                                          <AiFillQuestionCircle />
                                        )}
                                      </span>
                                    </Items>

                                    <Items>
                                      <strong>Motivo:</strong>
                                      <span>{taskDetails?.reason} </span>
                                    </Items>

                                    <Items>
                                      <strong>Ação:</strong>
                                      <span>{taskDetails?.action} </span>
                                    </Items>
                                  </Grid>
                                </Grid>
                              </DivDetails>
                            </Box>
                          </Collapse>
                        </TableCellCustom>
                      </TableRow>
                    ) : (
                      <></>
                    )}
                  </React.Fragment>
                );
              })}
          </TableBody>
        </TableCustom>
      </TableContainer>
      <TableFooterCustom>
        <TablePagination
          rowsPerPageOptions={[
            15,
            30,
            50,
            75,
            100,
            { value: numberRows, label: 'Todos' },
          ]}
          component="div"
          count={taskList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage="Linhas por página"
          backIconButtonText="Página anterior"
          nextIconButtonText="Próxima página"
        />
      </TableFooterCustom>

      {addModalEdit && (
        <ModalStyled
          size="lg"
          show={addModalEdit}
          onHide={() => {
            setAddModalEdit(false);
          }}
        >
          <Modal.Header>
            <ButtonDeleteModal
              type="button"
              onClick={() => {
                setDeleteTaskModal(true);
                setAddModalEdit(false);
              }}
            >
              <FiTrash2 size={20} /> Excluir
            </ButtonDeleteModal>

            <h1>
              Editar Tarefa
              <FiXCircle size={30} onClick={() => setAddModalEdit(false)} />
            </h1>
          </Modal.Header>

          <Modal.Body>
            <Form
              initialData={editTaskFilter}
              ref={formRef}
              onSubmit={handleEditTask}
            >
              <CreateEditGroup>
                <GroupOne>
                  <Label>Nome da Tarefa</Label>
                  <Input
                    className="input"
                    name="name"
                    icon={FiArrowRight}
                    type="text"
                    placeholder="Digite o nome da tarefa"
                    required
                  />

                  <Label>Responsável</Label>
                  <SelectPersonal
                    name="user_id"
                    option={optionLoad}
                    selectTask
                    id="selectuser"
                  />
                  <Label>Início</Label>
                  <DatePicker
                    name="date_start"
                    id="datepicker"
                    placeholderText="dd/mm/aaaa"
                  />

                  <Label>Término previsto</Label>
                  <DatePicker
                    name="date_preview"
                    id="datepicker"
                    placeholderText="dd/mm/aaaa"
                  />
                </GroupOne>

                <GroupTwo>
                  <div>
                    <Label>Motivo</Label>
                    <Textarea
                      name="reason"
                      icon={FiArrowRight}
                      placeholder="Digite o motivo"
                      required
                    />
                  </div>

                  <div>
                    <Label>Ação</Label>
                    <Textarea
                      name="action"
                      icon={FiArrowRight}
                      placeholder="Digite a ação"
                      required
                    />
                  </div>
                </GroupTwo>
              </CreateEditGroup>

              <Button type="submit">
                <FiEdit />
                Alterar
              </Button>
            </Form>
          </Modal.Body>
        </ModalStyled>
      )}

      {addShareModal && (
        <ModalStyled
          size="lg"
          show={addShareModal}
          onHide={() => {
            setShareModal(false);
          }}
        >
          <Modal.Header>
            <h1>
              Compartilhar
              <FiXCircle size={30} onClick={() => setShareModal(false)} />
            </h1>
          </Modal.Header>
          <Modal.Body>
            <Form
              initialData={editTaskFilter}
              ref={formRef}
              onSubmit={handleShareTask}
            >
              <CreateEditGroup>
                <GroupOne>
                  <Label>PDCA</Label>
                  <Select
                    name="pdca_id"
                    option={optionPdca}
                    selectTask
                    id="selectPdca"
                    required
                  />

                  <Label>Responsável</Label>
                  <SelectPersonal
                    name="user_id"
                    option={optionLoad}
                    selectTask
                    id="selectuser"
                    required
                  />

                  <Label>Nome da Tarefa</Label>
                  <Input
                    className="input"
                    name="name"
                    icon={FiArrowRight}
                    type="text"
                    placeholder="Digite o nome da tarefa"
                    required
                  />

                  <Label>Término previsto</Label>
                  <DatePicker
                    name="date_preview"
                    id="datepicker"
                    placeholderText="dd/mm/aaaa"
                  />
                </GroupOne>

                <GroupTwo>
                  <div>
                    <Label>Motivo</Label>
                    <Textarea
                      name="reason"
                      icon={FiArrowRight}
                      placeholder="Digite o motivo"
                      required
                    />
                  </div>
                  <div>
                    <Label>Ação</Label>
                    <Textarea
                      name="action"
                      icon={FiArrowRight}
                      placeholder="Digite a ação"
                      required
                    />
                  </div>
                </GroupTwo>
              </CreateEditGroup>

              <Button type="submit">
                <FiSave />
                Salvar
              </Button>
            </Form>
          </Modal.Body>
        </ModalStyled>
      )}

      {addJustificationModal && (
        <ModalStyled
          size="lg"
          show={addJustificationModal}
          onHide={() => setAddJustificationModal(false)}
        >
          <Modal.Header>
            <h1>
              Follow-up
              <FiXCircle
                size={30}
                onClick={() => setAddJustificationModal(false)}
              />
            </h1>
          </Modal.Header>

          <Modal.Body>
            <Group>
              <Col1>
                <Form
                  ref={formRefJustification}
                  onSubmit={handleCreateJustification}
                >
                  <Textarea
                    name="message"
                    icon={SiWechat}
                    placeholder="Digite seu follow-up"
                    required
                  />
                  <Button type="submit">
                    <FiSave /> Salvar
                  </Button>
                </Form>
              </Col1>
              <Col2>
                <TitleGroup>Justificativas</TitleGroup>
                {listJustification &&
                  listJustification.map((justification) => (
                    <Rows key={justification.id}>
                      <TextRows>
                        {justification.user_send} -{' '}
                        <span>
                          {format(
                            zonedTimeToUtc(
                              justification.created_at,
                              'America/Los_Angeles',
                            ),
                            'dd/MM/yyyy HH:mm',
                            {
                              locale: Brlocale,
                            },
                          )}
                        </span>
                        {justification.user_id_send.id === user.id ? (
                          <ButtonDelete
                            onClick={() => {
                              handleDeleteJustification(justification.id);
                            }}
                            type="button"
                          >
                            <FiTrash2 />
                          </ButtonDelete>
                        ) : (
                          <div />
                        )}
                        <p>{justification.message}</p>
                      </TextRows>
                      <hr />
                    </Rows>
                  ))}
              </Col2>
            </Group>
          </Modal.Body>
        </ModalStyled>
      )}

      {addStatusModal &&
        (creatorPdca?.user_id.id === user.id ? (
          <ModalStyled
            show={addStatusModal}
            onHide={() => setAddStatusModal(false)}
          >
            <Modal.Header>
              <h1>
                Alterar status
                <FiXCircle size={30} onClick={() => setAddStatusModal(false)} />
              </h1>
            </Modal.Header>

            <Modal.Body>
              <Form
                initialData={{ openTask }}
                ref={formRef}
                onSubmit={handleAlterStatus}
              >
                <strong>
                  Selecione um status
                  <SelectPersonal name="status_task" />
                </strong>
                <Button type="submit">
                  <FiEdit /> Alterar
                </Button>
              </Form>
            </Modal.Body>
          </ModalStyled>
        ) : (
          ''
        ))}

      {deleteTaskModal && (
        <ModalStyled
          show={deleteTaskModal}
          onHide={() => setDeleteTaskModal(false)}
        >
          <Modal.Header>
            <h1>
              Excluir tarefa?
              <FiXCircle size={30} onClick={() => setDeleteTaskModal(false)} />
            </h1>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Label>Realmente deseja excluir a tarefa?</Label>

              <ButtonBootstrap variant="danger" onClick={handleDeleteTask}>
                <FiTrash2 size={20} /> Excluir
              </ButtonBootstrap>
            </Row>
          </Modal.Body>
        </ModalStyled>
      )}

      {addTaskModal && (
        <ModalStyled
          size="lg"
          show={addTaskModal}
          onHide={() => {
            setAddTaskModal(false);
          }}
        >
          <Modal.Header>
            <h1>
              Adicionar Tarefa
              <FiXCircle size={30} onClick={() => setAddTaskModal(false)} />
            </h1>
          </Modal.Header>

          <Modal.Body>
            <Form ref={formRef} onSubmit={handleCreateTask}>
              <CreateEditGroup>
                <GroupOne>
                  <Label>Nome da Tarefa</Label>
                  <Input
                    className="input"
                    name="name"
                    icon={FiArrowRight}
                    type="text"
                    placeholder="Digite o nome da tarefa"
                    required
                  />

                  <Label>Responsável</Label>
                  <Autocomplete
                    id="user_id"
                    options={optionLoad}
                    getOptionLabel={(option: SelectRequest) => option.label}
                    renderInput={(params) => (
                      <TextFieldCustom
                        {...params}
                        name="user_id"
                        label="Selecione"
                        variant="outlined"
                      />
                    )}
                    onChange={(e, val) => {
                      setIdUserCreateTask(String(val?.value));
                    }}
                  />

                  <Label>Término previsto</Label>
                  <DatePicker
                    name="date_preview"
                    id="datepicker"
                    placeholderText="dd/mm/aaaa"
                  />
                </GroupOne>

                <GroupTwo>
                  <div>
                    <Label>Motivo</Label>
                    <Textarea
                      name="reason"
                      icon={FiArrowRight}
                      placeholder="Digite o motivo"
                      required
                    />
                  </div>
                  <div>
                    <Label>Ação</Label>
                    <Textarea
                      name="action"
                      icon={FiArrowRight}
                      placeholder="Digite a ação"
                      required
                    />
                  </div>
                </GroupTwo>
              </CreateEditGroup>

              <Button type="submit">
                <FiSave />
                Salvar
              </Button>
            </Form>
          </Modal.Body>
        </ModalStyled>
      )}

      {addFilterModal && (
        <ModalStyledFilter
          show={addFilterModal}
          onHide={() => setAddFilterModal(false)}
        >
          <Modal.Header>
            <h1>
              Filtrar
              <FiXCircle size={30} onClick={() => setAddFilterModal(false)} />
            </h1>
          </Modal.Header>

          <Modal.Body>
            <BuscaTask>
              <Fieldset>
                <Form ref={formRefFilter} onSubmit={handleSelectFilter}>
                  <FieldGroup>
                    <Field>
                      <GroupForm>
                        <InputSimple
                          name="task_name"
                          placeholder="Nome da tarefa"
                        />
                      </GroupForm>

                      <GroupForm>
                        <DatePicker
                          id="datepicker"
                          name="date_preview"
                          placeholderText="Data do fim previsto"
                        />
                      </GroupForm>
                    </Field>

                    <Field>
                      <GroupForm>
                        <Autocomplete
                          id="autoComplete"
                          options={optionLoad}
                          getOptionLabel={(option: SelectRequest) =>
                            option.label
                          }
                          renderInput={(params) => (
                            <TextFieldCustom
                              {...params}
                              label="Responsável"
                              variant="outlined"
                            />
                          )}
                          onChange={(e, val) => {
                            setIdUserFilterTask(String(val?.value));
                          }}
                        />
                      </GroupForm>

                      <GroupForm>
                        <GroupCheck>
                          <Checkbox name="pendente" textname="Pendente" />
                          <Checkbox name="andamento" textname="Andamento" />
                          <Checkbox name="concluido" textname="Concluído" />
                          <Checkbox name="cancelado" textname="Cancelado" />
                          <Checkbox name="feedback" textname="Feedback" />
                        </GroupCheck>
                      </GroupForm>
                    </Field>
                  </FieldGroup>

                  <ButtonSearch type="submit">
                    <BiSearchAlt />
                    Buscar
                  </ButtonSearch>
                </Form>
              </Fieldset>
            </BuscaTask>
          </Modal.Body>
        </ModalStyledFilter>
      )}
    </>
  );
};

export default EnhancedTable;
