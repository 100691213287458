import React, { useRef, useCallback, useState, useEffect } from 'react';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';

import { FiUser, FiMail, FaWhatsapp, FiXCircle, FiEdit } from 'react-icons/all';

import { toast } from 'react-toastify';

import Input from '../Input';
import Button from '../Button';
import Dropzone from '../Dropzone';
import CheckboxReset from '../CheckboxReset';

import { useUser } from '../../hooks/user';
import { useAuth } from '../../hooks/auth';
import { useTask } from '../../hooks/task';

import { Content, Title, Avatar, Form } from './styles';
import getValidationErrors from '../../utils/getValidationErrors';
import Select from '../SelectSimple';

interface UserRequest {
  id: string;
  avatar?: string;
  name: string;
  email: string;
  phone: string;
  status_user: boolean;
}

interface EditUser {
  info: UserRequest;
  onClose(): void;
}

interface SelectRequest {
  id: string;
  value: string;
  selectedOption?: boolean;
}

const CreateEditUser: React.FC<EditUser> = ({ info, onClose }) => {
  const formRef = useRef<FormHandles>(null);

  const { editUser, updateAvatar } = useUser();
  const { user, signOut } = useAuth();
  const { listUsersCompany } = useTask();

  const [status, setStatus] = useState<SelectRequest[]>([]);
  const [selectedFile, setSelectedFile] = useState<File>();

  useEffect(() => {
    setStatus(() => [
      {
        id: 'true',
        value: 'Ativo',
        selectedOption: info.status_user === true,
      },
      {
        id: 'false',
        value: 'Desativado',
        selectedOption: info.status_user === false,
      },
    ]);
  }, [info.status_user]);

  const handleFormEditUser = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('Digite o nome do usuário'),
          email: Yup.string().email().required('Digite o e-mail do usuário'),
          phone: Yup.string().required('Digite o whatsapp do usuário'),
        });

        await schema.validate(data, { abortEarly: false });

        await editUser({
          id: info.id,
          name: data.name,
          email: data.email,
          phone: data.phone,
          password: data.password,
          status_user: data.status_user,
        })
          .then(async () => {
            // update avatar
            if (selectedFile) {
              const dataForm = new FormData();

              dataForm.append('avatar', selectedFile);
              dataForm.append('user_id', info.id);
              await updateAvatar(dataForm)
                .then(() => {
                  listUsersCompany(user.company_id.id);
                  onClose();
                  info.id === user.id
                    ? signOut()
                    : toast.success('Alterado com sucesso!');
                })
                .catch((e) => {
                  toast.success('Não foi possível editar a foto!', e);
                });
            } else {
              listUsersCompany(user.company_id.id);
              onClose();
              info.id === user.id
                ? signOut()
                : toast.success('Alterado com sucesso!');
            }
          })
          .catch((e) => {
            onClose();
            toast.error('Verifique os dados.!', e);
          });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          toast.error('Verifique se os campos digitados estão corretos.');

          formRef.current?.setErrors(errors);
        }
      }
    },
    [
      editUser,
      info.id,
      listUsersCompany,
      onClose,
      user.company_id.id,
      selectedFile,
      updateAvatar,
      signOut,
      user.id,
    ],
  );

  return (
    <>
      <Content>
        <Title>
          Editar Perfil
          <FiXCircle size={30} onClick={onClose} />
        </Title>

        <Form ref={formRef} initialData={info} onSubmit={handleFormEditUser}>
          <Avatar>
            {info.avatar ? (
              <Dropzone
                onFileUploaded={setSelectedFile}
                urlImage={user.avatar}
              />
            ) : (
              <Dropzone onFileUploaded={setSelectedFile} />
            )}
          </Avatar>

          <Input
            name="name"
            icon={FiUser}
            placeholder="Digite o nome do usuário"
            required
          />
          <Input
            type="email"
            name="email"
            icon={FiMail}
            placeholder="Digite o e-mail do usuário"
            required
          />
          <Input
            name="phone"
            icon={FaWhatsapp}
            placeholder="Digite o telefone do usuário"
            required
          />

          {info.id !== user.id ? (
            <Select name="status_user" data={status} />
          ) : null}

          <CheckboxReset name="password" textname="Resetar senha" />

          <Button className="btnCadastrarAlterar" type="submit">
            <FiEdit /> Alterar
          </Button>
        </Form>
      </Content>
    </>
  );
};

export default CreateEditUser;
