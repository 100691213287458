import styled from 'styled-components';

export const Content = styled.div`
  #datepicker {
    flex: 1;
    width: 100% !important;
    background: #fff !important;
    border: 2px solid #fff !important;
    border-radius: 10px;
    text-align: center;
    align-items: center;
    margin-top: 0.5rem;
    padding: 0.35rem !important;
    color: #666360;
    font-size: 1rem;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;

    &::placeholder {
      color: #666360;
    }
  }
`;
