import React, { createContext, useCallback, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import api from '../services/api';

interface AuthState {
  token: string;
  user: {
    id: string;
    name: string;
    company_id: {
      id: string;
      name: string;
      amount: number;
    };
    account: number;
    email: string;
    phone: string;
    avatar?: string;
    updated_at: Date;
    status_user: boolean;
  };
}

interface SignInCredentials {
  id?: string;
  email: string;
  password?: string;
}

interface UpdatePasswordCredentials {
  id?: string;
  email: string;
  password?: boolean;
}

interface ObjectUser {
  id: string;
  name: string;
  company_id: {
    id: string;
    name: string;
    amount: number;
  };
  account: number;
  email: string;
  phone: string;
  avatar?: string;
  updated_at: Date;
  status_user: boolean;
}

interface AuthContextState {
  user: ObjectUser;
  verifyUser(): Promise<boolean>;
  emailExist(credentials: UpdatePasswordCredentials): Promise<void>;
  updatePassword(password: string): Promise<void>;
  signIn(credentials: SignInCredentials): Promise<boolean>;
  signOut(): void;
  info: UpdatePasswordCredentials | undefined;
  passwords: string | undefined;
  url: string;
}

const AuthContext = createContext<AuthContextState>({} as AuthContextState);

export const AuthProvider: React.FC = ({ children }) => {
  const [info, setInfo] = useState<UpdatePasswordCredentials>();
  const [passwords, setPasswords] = useState('');
  const history = useHistory();

  const newUrl = 'http://localhost:3334';
  // const newUrl = 'https://api.simplesplan.com.br';

  const [data, setData] = useState<AuthState>(() => {
    const token = sessionStorage.getItem('@SimplesPlan:token');
    const user = sessionStorage.getItem('@SimplesPlan:user');

    if (token && user) {
      return { token, user: JSON.parse(user) };
    }

    return {} as AuthState;
  });

  const emailExist = useCallback(async ({ email }) => {
    const response = await api.post('sessions/emailexist', { email });
    setInfo(response.data);
  }, []);

  const verifyUser = useCallback(async (): Promise<boolean> => {
    if (data && data.user && data.user.email) {
      const emailUser = data.user.email;
      const infos = await api.post('sessions/emailexist', { email: emailUser });
      if (data.user.updated_at !== infos.data.updated_at) {
        return true;
      }
    }

    return false;
  }, [data]);

  const updatePassword = useCallback(
    async (password) => {
      const response = await api.patch('users/password', {
        password,
        id: info?.id,
      });

      setPasswords(response.data);
    },
    [info],
  );

  const signIn = useCallback(async ({ email, password }): Promise<boolean> => {
    const response = await api.post('sessions', { email, password });

    const { token, user } = response.data;
    sessionStorage.setItem('@SimplesPlan:token', token);
    sessionStorage.setItem('@SimplesPlan:user', JSON.stringify(user));
    sessionStorage.setItem('@SimplesPlan:id', JSON.stringify(user.id));

    setData({ token, user });

    if (response.data.user.account === 3) {
      return true;
    }
    return false;
  }, []);

  const signOut = useCallback(() => {
    history.push('/');

    sessionStorage.removeItem('@SimplesPlan:token');
    sessionStorage.removeItem('@SimplesPlan:user');
    sessionStorage.removeItem('@SimplesPlan:id');
    sessionStorage.clear();
    setData({} as AuthState);
  }, [history]);

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        emailExist,
        updatePassword,
        signIn,
        signOut,
        info,
        passwords,
        verifyUser,
        url: newUrl,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextState {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}
