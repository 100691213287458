import React from 'react';
import { RouteProps as ReactDOMRouterProps, Redirect } from 'react-router-dom';

import { useAuth } from '../hooks/auth';

interface RouteProps extends ReactDOMRouterProps {
  isPrivate?: boolean;
  component: React.ComponentType;
  admin?: boolean;
}

const Route: React.FC<RouteProps> = ({
  admin = false,
  isPrivate = false,
  component: Component,
  ...rest
}) => {
  const authenticated = sessionStorage.getItem('@SimplesPlan:user');

  const { user } = useAuth();

  if (!authenticated && isPrivate) {
    return (
      <Redirect
        to={{
          pathname: isPrivate ? '/' : 'dashboard',
        }}
      />
    );
  }
  // eslint-disable-next-line no-else-return
  else if (admin) {
    if (user.account !== 3) return <Component />;

    return (
      <Redirect
        to={{
          pathname: '/planoacao',
        }}
      />
    );
  }

  return <Component />;
};

export default Route;
