/* eslint-disable no-nested-ternary */
import React from 'react';

import { Link } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/all';

import { ContentPDCA, MenuButton } from './styles';
import ListUsers from '../../../components/ListUsers';
import Header from '../../Header';

const ListUser: React.FC = () => {
  return (
    <>
      <Header />
      <ContentPDCA>
        <MenuButton>
          <Link to="/dashboard" className="linkVoltar">
            <FiArrowLeft />
            Voltar
          </Link>
        </MenuButton>

        <ListUsers />
      </ContentPDCA>
    </>
  );
};

export default ListUser;
