import styled from 'styled-components';
import { lighten } from 'polished';
import Modal from 'react-bootstrap/Modal';

export const Content = styled.div`
  height: 100%;
  width: 96%;
  align-self: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 1.875rem;
  margin-top: 0.875rem;
`;

export const ButtonShow = styled.button`
  background: none;
  border: none;
  margin-left: 88%;
  margin-bottom: 1rem;

  svg {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    color: #b89fdc;
  }
`;

export const MenuButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: right;
  margin-bottom: 20px;

  > a.linkVoltar {
    background: transparent;
    margin-right: 10px;
    text-decoration: none;
    padding: 5px 10px;
    align-self: center;
    border: 0;
    color: #5b437a;
    display: flex;
    transition: color 0.2s;

    &:hover {
      color: ${lighten(0.2, '#5b437a')};
    }

    svg {
      margin-right: 4px;
    }
  }
`;

export const DivButton = styled.div`
  div {
    display: flex;
  }
`;

export const ModalStyledFilter = styled(Modal)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  max-width: 98vw;

  div {
    border-radius: 10px;
    background: #f3f3f3;
    justify-content: center;
    align-items: center;
  }

  h1 {
    color: #957290;
    font-weight: 500;
    border: 0;
    margin-top: 4px;

    svg {
      position: absolute;
      right: 0.625rem;
      top: 0.625rem;
      color: #ff9000;
      cursor: pointer;
    }
  }

  Form {
    max-width: 320px;
    width: 100%;
    display: flex;
    flex-direction: column;

    @media (max-width: 800px) {
      max-width: 200px;
    }
  }
`;

export const GroupCheck = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 5px 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #edf7fe;
  align-items: center;
  text-align: center;
`;

export const BuscaTask = styled.div`
  display: flex;
  flex-direction: collumn;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.625rem;
  svg {
    width: 1.75rem;
    height: 1.75rem;
  }

  form {
    display: flex;
    align-items: center;

    @media (max-width: 1033px) {
      display: flex;
      flex-direction: column;
    }
  }
  @media (max-width: 1033px) {
    display: flex;
    flex-direction: column;
  }
`;

export const ButtonBuscar = styled.button`
  margin-top: 1rem;
  width: 50%;
  height: 40px;
  border-radius: 5px;
  background: #75a2ff;
  border: 1px solid #75a2ff;
  color: #fff;
  transition: 0.2s;
  &:hover {
    background: #fff;
    color: #75a2ff;
    border: 1px solid #75a2ff;
  }
`;
