import React, {
  createContext,
  useCallback,
  useContext,
  useState,
  useEffect,
} from 'react';

import api from '../services/api';
import { useAuth } from './auth';

interface PDCARequestCreate {
  id?: string;
  name: string;
  description: string;
  company_id?: string;
  user_id?: string;
}

interface PDCARequest {
  id: string;
  name: string;
  description: string;
  company_id: {
    id: string;
    name: string;
  };
  user_id: {
    id: string;
    name: string;
  };
}

interface PDCATaskRequest {
  namecompany: string;
  id: string;
  namepdca: string;
  namegestor: string;
  user_id: string;
}

interface PDCAContextData {
  createPDCA(pdca: PDCARequestCreate): void;
  editPDCA(pdca: PDCARequestCreate): void;
  pdca: PDCARequest[];
  pdcaTask: PDCATaskRequest[];
  listPDCA(): void;
}

const PDCAContext = createContext<PDCAContextData>({} as PDCAContextData);

const PDCAProvider: React.FC = ({ children }) => {
  const [pdca, setPdca] = useState<PDCARequest[]>([]);
  const [pdcaTask, setPdcaTask] = useState<PDCATaskRequest[]>([]);
  const { user } = useAuth();

  const listPDCA = useCallback(async () => {
    if (user && user.id) {
      if (user.account === 1) {
        const pdcaFull = await api.get(`pdca/full/${user.company_id.id}`);
        setPdca(pdcaFull.data);
      } else {
        const pdcaResponse = await api.get(`pdca/${user.id}`);

        const pdcaTaskResponse = await api.get(`task/pdcamytask/${user.id}`);

        setPdca(pdcaResponse.data);
        setPdcaTask(pdcaTaskResponse.data);
      }
    }
  }, [user]);

  useEffect(() => {
    listPDCA();
  }, [listPDCA]);

  const createPDCA = useCallback(
    async ({
      name,
      description,
      company_id,
    }: PDCARequestCreate): Promise<void> => {
      const response: PDCARequestCreate = {
        name,
        description,
        company_id,
        user_id: user.id,
      };

      await api.post('pdca', response);
      await listPDCA();
    },
    [user, listPDCA],
  );

  const editPDCA = useCallback(
    async ({ id, name, description }: PDCARequestCreate): Promise<void> => {
      await api.patch('pdca/update', { id, name, description });
      await listPDCA();
    },
    [listPDCA],
  );

  return (
    <PDCAContext.Provider
      value={{ createPDCA, editPDCA, pdca, listPDCA, pdcaTask }}
    >
      {children}
    </PDCAContext.Provider>
  );
};

function usePDCA(): PDCAContextData {
  const context = useContext(PDCAContext);

  if (!context) {
    throw new Error('usePDCA must be used within a PDCAProvider');
  }

  return context;
}

export { PDCAProvider, usePDCA };
