import React, { useState, useEffect } from 'react';

import { useHistory, Link } from 'react-router-dom';

import {
  FiArrowLeft,
  FcLeave,
  FcAbout,
  FcApproval,
  FcCancel,
  FcFeedback,
} from 'react-icons/all';

import Tooltip from '@material-ui/core/Tooltip';
import ListPdca from '../../../components/ListPdca';

import {
  ContentPDCA,
  MenuButton,
  Fieldset,
  Field,
  FieldGroup,
  ListCustom,
  ListItemCustom,
} from './styles';

import { useTask } from '../../../hooks/task';
import Header from '../../Header';

const ViewPDCA: React.FC = () => {
  const [andamento, setAndamento] = useState(0);
  const [concluido, setConcluido] = useState(0);
  const [cancelado, setCancelado] = useState(0);
  const [pendente, setPendente] = useState(0);
  const [feedback, setFeedback] = useState(0);

  const { tasks, setPdcaID } = useTask();
  const [taskList, setTaskList] = useState(tasks);

  const history = useHistory();
  const pdca_id = history.location.state;

  useEffect(() => {
    const pendentes = taskList.filter((task) => task.status_task === 'Pendente')
      .length;
    const andamentos = taskList.filter(
      (task) => task.status_task === 'Andamento',
    ).length;
    const concluidos = taskList.filter(
      (task) => task.status_task === 'Concluído',
    ).length;
    const cancelados = taskList.filter(
      (task) => task.status_task === 'Cancelado',
    ).length;
    const feedbacks = taskList.filter((task) => task.status_task === 'Feedback')
      .length;
    setFeedback(feedbacks);
    setPendente(pendentes);
    setAndamento(andamentos);
    setConcluido(concluidos);
    setCancelado(cancelados);
  }, [taskList, setTaskList]);

  useEffect(() => {
    setPdcaID(String(pdca_id));
    setTaskList(tasks);
  }, [setPdcaID, tasks, pdca_id]);
  return (
    <>
      <Header />
      <ContentPDCA>
        <MenuButton>
          <Link to="/dashboard" className="linkVoltar">
            <FiArrowLeft />
            Voltar
          </Link>
        </MenuButton>

        <Fieldset>
          <FieldGroup>
            <Tooltip title="Status tarefas">
              <ListCustom aria-labelledby="nested-list-subheader">
                <ListItemCustom button>
                  <FcLeave /> Pendente: {pendente}
                </ListItemCustom>

                <ListItemCustom button>
                  <FcAbout />
                  Andamento: {andamento}
                </ListItemCustom>

                <ListItemCustom button>
                  <FcApproval />
                  Concluído: {concluido}
                </ListItemCustom>

                <ListItemCustom button>
                  <FcFeedback />
                  Feedback: {feedback}
                </ListItemCustom>

                <ListItemCustom button>
                  <FcCancel />
                  Cancelado: {cancelado}
                </ListItemCustom>
              </ListCustom>
            </Tooltip>
            <Field>
              <ListPdca pdca_id={String(pdca_id)} />
            </Field>
          </FieldGroup>
        </Fieldset>
      </ContentPDCA>
    </>
  );
};

export default ViewPDCA;
